import { connect } from 'react-redux';
import AdminAccount from './AdminAccount';
import { GlobalState } from '../../reducers';
import { getUsername } from '../../selectors/user';

const mapStateToProps = (state: GlobalState) => ({
  username: getUsername(state) as string
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminAccount);
