export enum Role {
	Admin_Pharmacies_Edit = 'Admin_Pharmacies_Edit',
	Admin_Pharmacies_Add = 'Admin_Pharmacies_Add',
	Admin_Pharmacies_Toggle = 'Admin_Pharmacies_Toggle',
	Admin_Users_Edit = 'Admin_Users_Edit',
	Admin_Users_Delete = 'Admin_Users_Delete',
	Admin_Services_Edit = 'Admin_Services_Edit',
	Admin_Offers_Edit = 'Admin_Offers_Edit',
	Admin_Full = 'Admin_Full',
	Admin_ReadOnly = 'Admin_ReadOnly'
}