import React, { useState, useEffect } from 'react';
import { Account } from '../../types/account';
import Config from '../../config';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'react-bootstrap/Table';
import { Role } from '../../types/role';
import Modal from 'react-bootstrap/Modal';
import { Pharmacy } from '../../types/pharmacy';
import { getPharmacySelectLabel } from '../../utils/pharmacy';
import Select, { ValueType } from 'react-select';
import { hasRole } from '../../utils/auth';

type OptionType = {
  value: string;
  label: string;
};

export default ({
  account,
  associatedPharmacies,
  setAssociatedPharmacies,
}: {
  account: Account;
  associatedPharmacies: {
    [id: string]: string;
  };
  setAssociatedPharmacies: (pharmacies: { [id: string]: string }) => void;
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
  const [newPharmacy, setNewPharmacy] = useState<ValueType<OptionType>>(null);

  useEffect(() => {
    const fetchPharmacies = async () => {
      try {
        const url = Config.getConfigVar('pharmaciesEndpoint');
        const result = await axios.get(url);
        setPharmacies(result.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(true);
      }
    };

    if (showAddModal) {
      fetchPharmacies();
    }
  }, [showAddModal]);

  return (
    <>
      <p>
        These are pharmacies {account.email} has access to in the <strong>Pharmacy Portal</strong>,
        and the ability to edit in <strong>Back Office</strong>.
      </p>
      <Table hover>
        <thead>
          <tr>
            <th>Pharmacy</th>
            {hasRole(Role.Admin_Full) && <th>Remove access</th>}
          </tr>
        </thead>
        <tbody>
          {Object.values(associatedPharmacies).length ? (
            Object.entries(associatedPharmacies).map(([id, name]) => (
              <tr key={id}>
                <td>{name}</td>
                {hasRole(Role.Admin_Full) && (
                  <td>
                    <Button
                      variant="link"
                      onClick={() => {
                        const pharms = { ...associatedPharmacies };
                        delete pharms[id];
                        setAssociatedPharmacies(pharms);
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>
                <em>User does not currently have access to any pharmacies</em>
              </td>
            </tr>
          )}
          {/* 
            Limit on pharmacy IDs that can be stored in JWT, see 
            https://iwavicenna.atlassian.net/jira/software/projects/AP2/boards/3?selectedIssue=AP2-1230 
          */}
          {hasRole(Role.Admin_Full) &&
            (Object.values(associatedPharmacies).length < 6 ? (
              <tr>
                <td colSpan={2}>
                  <Button variant="primary" onClick={() => setShowAddModal(true)}>
                    <FontAwesomeIcon icon="plus-circle" /> Add pharmacy access
                  </Button>
                </td>
              </tr>
            ) : (
              <Alert variant="warning">
                A maximum of 6 pharmacies can be added, please remove one to add new access
              </Alert>
            ))}
        </tbody>
      </Table>
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Pharmacy Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <Alert variant="danger">Error loading pharmacies</Alert>
          ) : loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <p>Select a pharmacy from the list below.</p>
              <p>
                This will give the user access to the pharmacy portal and the ability to edit the
                pharmacy in the back office.
              </p>
              <Select
                options={pharmacies.map((p) => ({ value: p.id, label: getPharmacySelectLabel(p) }))}
                value={newPharmacy}
                onChange={setNewPharmacy}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!newPharmacy}
            onClick={() => {
              const pharmObj = pharmacies.find((p) => p.id === (newPharmacy as OptionType).value);
              if (pharmObj) {
                setAssociatedPharmacies({
                  ...associatedPharmacies,
                  [pharmObj.id]: pharmObj.businessName,
                });
              }
              setShowAddModal(false);
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
