import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Patient } from '../../types/patient';
import { Order } from '../../types/order';
import { Pharmacy } from '../../types/pharmacy';
import { Account } from '../../types/account';
import axios, { AxiosResponse } from 'axios';
import Config from '../../config';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'react-bootstrap/Badge';
import SaveModal from '../SaveModal';
import { Role } from '../../types/role';
import { hasRole } from '../../utils/auth';
import AuditTrailTab from '../AuditTrailTab';
import DetailsTab from './DetailsTab';
import PharmacyTab from './PharmacyTab';
import GDPRTab from './GDPRTab';
import IM1Tab from './IM1Tab';
import ReminderHistoryTab from './ReminderHistoryTab';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

type PatientResponse = {
  patient: Patient;
  account: Account;
  orders: Order[];
  pharmacy: Pharmacy;
};

export default () => {
  const { patientId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [initialPatient, setInitialPatient] = useState<Patient | null>(null);
  const [account, setAccount] = useState<Account | null>(null);
  const [pharmacy, setPharmacy] = useState<Pharmacy | null>(null);
  const [validated, setValidated] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [detailsError, setDetailsError] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const fetchPatient = async () => {
    try {
      setLoading(true);
      const url = Config.getConfigVar('patientsEndpoint');
      const result = await axios.get<any, AxiosResponse<PatientResponse>>(`${url}/${patientId}`);
      setAccount(result.data.account);
      setPatient(result.data.patient);
      setInitialPatient(result.data.patient);
      setPharmacy(result.data.pharmacy);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchPatient();
    // eslint-disable-next-line
  }, [patientId]);

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error || !initialPatient || !patient || !account) {
    return <Alert variant="danger">Error loading patient</Alert>;
  }

  const handleSubmit = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setDetailsError(true);
      setShowSaveModal(false);
    } else {
      setDetailsError(false);
      setShowSaveModal(true);
    }

    setValidated(true);
  };

  const detailsTitle = (
    <>
      Details{' '}
      {detailsError && (
        <Badge pill variant="danger">
          !
        </Badge>
      )}
    </>
  );

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {/* Block enter key submission with a disabled submit */}
      <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true"></button>
      <Row className="my-3">
        <Col sm={6}>
          <h2>
            {initialPatient.firstName} {initialPatient.lastName}{' '}
            {initialPatient.patientIntegration && (
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip id="tooltip-im1">
                    Patient is IM1 registered
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon="notes-medical" />
              </OverlayTrigger>
            )}
          </h2>
        </Col>
        {hasRole(Role.Admin_Users_Edit) && (
          <Col sm={6}>
            <p className="text-right">
              <Button
                variant="secondary"
                disabled={initialPatient === patient}
                onClick={() => {
                  setPatient(initialPatient);
                  setDetailsError(false);
                  setValidated(false);
                }}
              >
                <FontAwesomeIcon icon="history" /> Reset
              </Button>{' '}
              <Button variant="primary" disabled={initialPatient === patient} type="submit">
                <FontAwesomeIcon icon="save" /> Save
              </Button>
            </p>
          </Col>
        )}
      </Row>
      <Tabs defaultActiveKey="details" id="pharmacy-tabs" className="my-3">
        <Tab eventKey="details" title={detailsTitle} className="my-3 mx-2">
          <DetailsTab patient={patient} account={account} setPatient={setPatient} />
        </Tab>
        {pharmacy && (
          <Tab eventKey="pharm" title="Pharmacy &amp; GP" className="my-3 mx-2">
            <PharmacyTab
              patient={patient}
              pharmacy={pharmacy}
              setPatient={setPatient}
              setPharmacy={setPharmacy}
            />
          </Tab>
        )}
        {hasRole(Role.Admin_Users_Delete) && (
          <Tab eventKey="gdpr" title="GDPR" className="my-3 mx-2">
            <GDPRTab patient={patient} />
          </Tab>
        )}
        {hasRole(Role.Admin_ReadOnly) && (
          <Tab eventKey="audittrail" title="Audit Trail" className="my-3 mx-2">
            <AuditTrailTab query={`entityId=${patient.id}`} />
          </Tab>
        )}
        <Tab eventKey="im1audit" title="IM1 Audit Trail" className="my-3 mx-2">
          <IM1Tab patient={patient} />
        </Tab>
        <Tab eventKey="reminderhistory" title="Reminder history" className="my-3 mx-2">
          <ReminderHistoryTab patient={patient} />
        </Tab>
      </Tabs>
      <SaveModal
        showSaveModal={showSaveModal}
        setShowSaveModal={setShowSaveModal}
        saving={modalLoading}
        onSave={async () => {
          try {
            setModalLoading(true);
            const url = Config.getConfigVar('updatePatientEndpoint');
            await axios.patch(url, patient);
            setShowSaveModal(false);
            setModalLoading(false);
            fetchPatient();
          } catch (e) {
            setError(true);
          }
        }}
      />
    </Form>
  );
};

export type PatientTabProps = {
  patient?: Patient;
  account?: Account;
  orders?: Order[];
  pharmacy?: Pharmacy;
  setPatient?: (patient: Patient) => void;
  setPharmacy?: (pharmacy: Pharmacy) => void;
};
