import { GroupTabType } from './Group';
import Form from 'react-bootstrap/Form';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types/role';

type DetailTabProps = GroupTabType & {
  groupId: string;
};

export default ({ group, setGroup, groupId }: DetailTabProps) => (
  <>
    <h4 className="mb-4">Details</h4>
    <Form.Group as={Row}>
      <Form.Label column sm={2}>
        Group Name
      </Form.Label>
      <Col sm={7}>
        <Form.Control
          type="text"
          required
          placeholder="Group Name"
          value={group.name}
          onChange={(e: any) => {
            setGroup({
              ...group,
              name: e.target.value,
            });
          }}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Label column sm={2}>
        Telephone
      </Form.Label>
      <Col sm={7}>
        <Form.Control
          type="text"
          required
          placeholder="Telephone"
          value={group.telephoneNumber}
          onChange={(e: any) => {
            setGroup({
              ...group,
              telephoneNumber: e.target.value,
            });
          }}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Label column sm={2}>
        Email
      </Form.Label>
      <Col sm={7}>
        <Form.Control
          type="email"
          placeholder="Email"
          value={group.email}
          onChange={(e: any) => {
            setGroup({
              ...group,
              email: e.target.value,
            });
          }}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Label column sm={2}>
        Key Contact
      </Form.Label>
      <Col sm={7}>
        <Form.Control
          type="text"
          placeholder="Name"
          value={group.keyContact}
          onChange={(e: any) => {
            setGroup({
              ...group,
              keyContact: e.target.value,
            });
          }}
        />
      </Col>
    </Form.Group>
    {hasRole(Role.Admin_Pharmacies_Toggle) && (
      <>
        <h4 className="my-4">Group Settings</h4>
        <Form.Group as={Row} className="mb-0">
          <Form.Label column sm={2}>
            Service booking live
          </Form.Label>
          <Col sm={7} className="mx-3">
            <Row>
              <Form.Check
                type="checkbox"
                checked={group.live}
                onChange={(e: any) => {
                  setGroup({
                    ...group,
                    live: !group.live,
                  });
                }}
              />
            </Row>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-0">
          <Form.Label column sm={2}>
            Use Custom Logo
          </Form.Label>
          <Col sm={7} className="mx-3">
            <Row>
              <Form.Check
                type="checkbox"
                checked={group.useCustomLogo}
                onChange={(e: any) => {
                  setGroup({
                    ...group,
                    useCustomLogo: !group.useCustomLogo,
                  });
                }}
              />
            </Row>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-0">
          <Form.Label column sm={2}>
            Number of pharmacies
          </Form.Label>
          <Col sm={7} className="mx-3">
            <Row>
              <Form.Control
                type="number"
                required
                placeholder="Number"
                value={group.numberOfPharmaciesAllowed}
                onChange={(e: any) => {
                  setGroup({
                    ...group,
                    numberOfPharmaciesAllowed: e.target.value,
                  });
                }}
              />
            </Row>
          </Col>
        </Form.Group>
        {groupId !== 'new' && (
          <>
            <h4 className="my-4">Group API Keys</h4>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Booking Public API Key
              </Form.Label>
              <Col sm={7} className="mx-3">
                <Row>
                  <Form.Control
										type="text"
										disabled
                    value={group.bookingApiKey}
                  />
                </Row>
              </Col>
            </Form.Group>
						<Form.Group as={Row}>
              <Form.Label column sm={2}>
                Booking Admin API Key
              </Form.Label>
              <Col sm={7} className="mx-3">
                <Row>
                  <Form.Control
										type="text"
										disabled
                    value={group.bookingAdminApiKey}
                  />
                </Row>
              </Col>
            </Form.Group>
          </>
        )}
      </>
    )}
  </>
);
