import React, { useEffect, useRef, useState } from 'react';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import axios, { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../../config';
import AdminAccount from './AdminAccount.container';
import { Account as AccountType } from '../../types/account';
import Form from 'react-bootstrap/Form';
import useDebounce from '../../hooks/useDebounce';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types/role';
import Pagination from '../Pagination';

const itemsPerPage = 50;

type AccountResponse = {
  accounts: AccountType[];
  items: number;
  currentPage: number;
  totalPages: number;
};

export default () => {
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [accounts, setAccounts] = useState<AccountType[]>([]);
  const [totalAccounts, setTotalAccounts] = useState<number | null>(null);
  const [filterParam, setFilterParam] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [search, setSearch, signal] = useDebounce('', 500, {
    leading: false,
    trailing: true,
  });

  const prevSearchRef = useRef<string>();
  const prevFilterRef = useRef<string>();

  const fetchAccounts = async (searchTerm: string) => {
    try {
      setLoading(true);
      setTotalAccounts(null);
      const url = Config.getConfigVar('accountsEndpoint');
      const result = await axios.get<any, AxiosResponse<AccountResponse>>(
        `${url}?offset=${
          (page - 1) * itemsPerPage
        }&limit=${itemsPerPage}&q=${searchTerm}&adminAccounts=true${filterParam}`
      );
      setAccounts(result.data.accounts);
      setTotalAccounts(result.data.items);
      setPages(result.data.totalPages);
      setLoading(false);
    } catch (e) {
      setTotalAccounts(null);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchAccounts('');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (search !== prevSearchRef.current || filterParam !== prevFilterRef.current) {
      setPage(1);
      prevSearchRef.current = search;
      prevFilterRef.current = filterParam;
    }
    fetchAccounts(search);
    // As this is debounced, we don't want to watch for changes to search string itself
    // eslint-disable-next-line
  }, [signal, page, filterParam]);

  let content = null;

  if (loading) {
    content = <Spinner animation="border" variant="primary" />;
  } else if (error) {
    content = <Alert variant="danger">Error loading admin accounts</Alert>;
  } else if (accounts.length === 0) {
    content = <em>No admin accounts found</em>;
  } else {
    content = (
      <>
        <Table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map((account) => (
              <tr key={account.id}>
                <td>
                  <Link to={`${match.url}/${account.id}`}>{account.email}</Link>
                </td>
                <td>{account.accountStatus}</td>
                <td>
                  <Link to={`${match.url}/${account.id}`}>
                    <FontAwesomeIcon icon="edit" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {pages > 1 && <Pagination currentPage={page} totalPages={pages} setPage={setPage} />}
      </>
    );
  }

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:accountId`}>
          <AdminAccount />
        </Route>
        <Route path={match.path}>
          <Row className="my-3">
            <Col sm={3}>
              <h2>Admin Accounts</h2>
            </Col>
            <Col sm={6}>
              <Form>
                <Form.Control
                  type="text"
                  placeholder="Search by email"
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}
                />
              </Form>
            </Col>
            {hasRole(Role.Admin_Full) && (
              <Col sm={3}>
                <p className="text-right">
                  <Link to={`${match.url}/new`}>
                    <Button variant="primary">
                      <FontAwesomeIcon icon="plus-circle" /> Add new admin
                    </Button>
                  </Link>
                </p>
              </Col>
            )}
          </Row>
          <Row className="mb-4">
            <Col sm={4}>
              <Form>
                <Form.Control
                  as="select"
                  value={filterParam}
                  onChange={(e: any) => setFilterParam(e.target.value)}
                >
                  <option value={''}>All</option>
                  <option value={'&statusFilter=Active'}>Active (fully registered)</option>
                  <option value={'&statusFilter=Created'}>Created (email not verified)</option>
                  <option value={'&statusFilter=Verified'}>
                    Verified (registration not finished)
                  </option>
                  <option value={'&statusFilter=Deactivated'}>Deactivated (access revoked)</option>
                </Form.Control>
              </Form>
            </Col>
            <Col sm={8}>
              {totalAccounts !== null && (
                <p className="text-right">
                  <strong>Count: </strong>
                  {totalAccounts}
                </p>
              )}
            </Col>
          </Row>
          <Row className="mx-1">{content}</Row>
        </Route>
      </Switch>
    </>
  );
};
