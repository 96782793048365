import React, { useEffect, useState, useRef } from 'react';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import axios, { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../../config';
import Account from './Account';
import { Account as AccountType } from '../../types/account';
import Form from 'react-bootstrap/Form';
import format from 'date-fns/format';
import useDebounce from '../../hooks/useDebounce';
import Pagination from '../Pagination';
import Button from 'react-bootstrap/Button';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types/role';

const itemsPerPage = 50;

type AccountResponse = {
  accounts: AccountType[];
  items: number;
  currentPage: number;
  totalPages: number;
};

export default () => {
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const [error, setError] = useState(false);
  const [accounts, setAccounts] = useState<AccountType[]>([]);
  const [totalAccounts, setTotalAccounts] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [filterParam, setFilterParam] = useState('');
  const [search, setSearch, signal] = useDebounce('', 500, {
    leading: false,
    trailing: true,
  });

  const prevSearchRef = useRef<string>();
  const prevFilterRef = useRef<string>();

  const fetchAccounts = async (searchTerm: string) => {
    try {
      setLoading(true);
      setTotalAccounts(null);
      const url = Config.getConfigVar('accountsEndpoint');
      const result = await axios.get<any, AxiosResponse<AccountResponse>>(
        `${url}?q=${searchTerm}&offset=${
          (page - 1) * itemsPerPage
        }&limit=${itemsPerPage}${filterParam}`
      );
      setAccounts(result.data.accounts);
      setTotalAccounts(result.data.items);
      setPages(result.data.totalPages);
      setLoading(false);
    } catch (e) {
      setTotalAccounts(null);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchAccounts('');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (search !== prevSearchRef.current || filterParam !== prevFilterRef.current) {
      setPage(1);
      prevSearchRef.current = search;
      prevFilterRef.current = filterParam;
    }
    fetchAccounts(search);
    // As this is debounced, we don't want to watch for changes to search string itself
    // eslint-disable-next-line
  }, [signal, page, filterParam]);

  const downloadAccounts = async () => {
    try {
      setDownloadingCSV(true);
      const url = Config.getConfigVar('accountsCsvEndpoint');
      const result = await axios.get<any, AxiosResponse<any>>(`${url}`, {
        responseType: 'blob',
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setTotalAccounts(null);
      setError(true);
    } finally {
      setDownloadingCSV(false);
    }
  };

  let content = null;

  if (loading) {
    content = <Spinner animation="border" variant="primary" />;
  } else if (error) {
    content = <Alert variant="danger">Error loading accounts</Alert>;
  } else if (accounts.length === 0) {
    content = <em>No accounts found</em>;
  } else {
    const dateFormat = Config.getConfigVar('dateFormat');

    content = (
      <>
        <Table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Signed Up</th>
              <th>Platform</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map((account) => (
              <tr key={account.id}>
                <td>
                  <Link to={`${match.url}/${account.id}`}>{account.email}</Link>
                </td>
                <td>{format(new Date(account.createdDt), dateFormat)}</td>
                <td>{getPlatformText(account.platform)}</td>
                <td>{account.accountStatus}</td>
                <td>
                  <Link to={`${match.url}/${account.id}`}>
                    <FontAwesomeIcon icon="edit" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {pages > 1 && <Pagination currentPage={page} totalPages={pages} setPage={setPage} />}
      </>
    );
  }

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:accountId`}>
          <Account />
        </Route>
        <Route path={match.path}>
          <Row className="my-3">
            <Col sm={3}>
              <h2>User Accounts</h2>
            </Col>
            <Col sm={6}>
              <Form>
                <Form.Control
                  type="text"
                  placeholder="Search by email"
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}
                />
              </Form>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={4}>
              <Form>
                <Form.Control
                  as="select"
                  value={filterParam}
                  onChange={(e: any) => setFilterParam(e.target.value)}
                >
                  <option value={''}>All</option>
                  <option value={'&statusFilter=Active'}>Active (fully registered)</option>
                  <option value={'&statusFilter=Created'}>Created (email not verified)</option>
                  <option value={'&statusFilter=Verified'}>
                    Verified (registration not finished)
                  </option>
                  <option value={'&statusFilter=Deactivated'}>Deactivated (access revoked)</option>
                </Form.Control>
              </Form>
            </Col>
            <Col sm={8}>
              {totalAccounts !== null && (
                <p className="text-right">
                  <strong>Count: </strong>
                  {totalAccounts}{' '}
                  {(hasRole(Role.Admin_Full) ||
                    hasRole(Role.Admin_ReadOnly) ||
                    hasRole(Role.Admin_Users_Edit) ||
                    hasRole(Role.Admin_Users_Delete)) && (
                    <Button
                      href="#"
                      onClick={async () => await downloadAccounts()}
                      disabled={downloadingCSV}
                      size="sm"
                    >
                      {downloadingCSV ? 'Exporting...' : 'Export'}
                    </Button>
                  )}
                </p>
              )}
            </Col>
          </Row>
          <Row className="mx-1">{content}</Row>
        </Route>
      </Switch>
    </>
  );
};

export const getPlatformText = (platform: string) => {
  switch (platform) {
    case 'apns':
      return 'Apple';
    case 'fcm':
      return 'Android';
    case 'web':
      return 'Web';
    default:
      return '?';
  }
};
