import { actionTypes } from '../actions/signIn';

export type signInStateType = {
  signInMessage: string | null;
};

export const signInState: signInStateType = {
  signInMessage: null
};

export default (state = signInState, action: Action): signInStateType => {
  switch (action.type) {
    case actionTypes.SET_MESSAGE:
      return {
        ...state,
        signInMessage: action.payload.message,
      };
    case actionTypes.CLEAR_MESSAGE:
      return signInState;
    default:
      return state;
  }
};
