import { createSelector } from 'reselect';
import { GlobalState } from '../reducers';

export const getUsername = (state: GlobalState) => state.user.username;

export const getRoles = (state: GlobalState) => state.user.roles;

export const getPharmacyAccess = (state: GlobalState) => state.user.pharmacyIDs;

export const hasPharmacyAccess = createSelector([getPharmacyAccess], (pharmacyIDs) => 
	pharmacyIDs.length > 0);