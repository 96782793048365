import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Account, defaultAccount } from '../../types/account';
import { useParams, useHistory } from 'react-router-dom';
import Config from '../../config';
import axios, { AxiosResponse } from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Role } from '../../types/role';
import SaveModal from '../SaveModal';
import { hasRole } from '../../utils/auth';
import AuditTrailTab from '../AuditTrailTab';
import AccountTab from './AccountTab';
import PharmaciesTab from './PharmaciesTab';
import GroupsTab from './GroupsTab';
import RolesTab from './RolesTab';
import EditAccountStatusModal from '../EditAccountStatusModal';

type AdminAccountResponse = {
  account: Account;
  roles: Role[];
  pharmacyNames: {
    [id: string]: string;
  };
  groupNames: {
    [id: string]: string;
  };
};

type AdminAccountProps = {
  username: string;
};

export default ({ username }: AdminAccountProps) => {
  const { accountId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [account, setAccount] = useState<Account | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [initialRoles, setInitialRoles] = useState<Role[]>([]);
  const [associatedPharmacies, setAssociatedPharmacies] = useState({});
  const [initialPharmacies, setInitialPharmacies] = useState({});
  const [associatedGroups, setAssociatedGroups] = useState({});
  const [initialGroups, setInitialGroups] = useState({});
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [addingAccount, setAddingAccount] = useState(false);
  const [resendingEmail, setResendingEmail] = useState(false);
  const [resendingError, setResendingError] = useState(false);
  const [showEditStatusModal, setShowEditStatusModal] = useState(false);

  const fetchAccount = async () => {
    try {
      setLoading(true);
      const url = Config.getConfigVar('adminAccountEndpoint');
      const result = await axios.get<any, AxiosResponse<AdminAccountResponse>>(
        `${url}/${accountId}`
      );
      setAccount(result.data.account);
      setRoles(result.data.roles);
      setInitialRoles(result.data.roles);
      setAssociatedPharmacies(result.data.pharmacyNames);
      setInitialPharmacies(result.data.pharmacyNames);
      setAssociatedGroups(result.data.groupNames);
      setInitialGroups(result.data.groupNames);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (accountId === 'new') {
      setAccount(defaultAccount);
      setLoading(false);
    } else {
      fetchAccount();
    }
    // eslint-disable-next-line
  }, [accountId]);

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error || !account) {
    return <Alert variant="danger">An error occurred</Alert>;
  }

  if (account.accountStatus === 'Created') {
    return (
      <>
        <Alert variant="warning">Waiting for user to create their password</Alert>
        {resendingError && (
          <Alert variant="danger">There was an error resending the verification email</Alert>
        )}
        <Button
          variant="primary"
          disabled={resendingEmail}
          onClick={async () => {
            try {
              setResendingError(false);
              setResendingEmail(true);
              const url = Config.getConfigVar('resendVerificationEndpoint');
              await axios.post(url, {
                email: account.email,
              });
              setResendingEmail(false);
            } catch {
              setResendingError(true);
              setResendingEmail(false);
            }
          }}
        >
          {resendingEmail ? 'Sending' : 'Resend verification email (Back Office)'}
        </Button>
        <p />
        <Button
          variant="primary"
          disabled={resendingEmail}
          onClick={async () => {
            try {
              setResendingError(false);
              setResendingEmail(true);
              const url = Config.getConfigVar('resendVerificationEndpoint');
              await axios.post(url, {
                email: account.email,
                completeInPortal: true,
              });
              setResendingEmail(false);
            } catch {
              setResendingError(true);
              setResendingEmail(false);
            }
          }}
        >
          {resendingEmail ? 'Sending' : 'Resend verification email (Pharmacy)'}
        </Button>
        <p />
        {hasRole(Role.Admin_Full) && (
          <Button
            variant='danger'
            disabled={account.email === username}
            onClick={() => setShowEditStatusModal(true)}
          >
            Deactivate account
          </Button>
        )}
        <EditAccountStatusModal
          showModal={showEditStatusModal}
          setShowModal={setShowEditStatusModal}
          account={account}
          fetchAccount={fetchAccount}
        />
      </>
    );
  }

  return (
    <>
      <Row className="my-3">
        <Col sm={6}>
          <h2>{account.id === '' ? <em>New admin user</em> : account.email}</h2>
        </Col>
        <Col sm={6}>
          <p className="text-right">
            {accountId === 'new' ? (
              <Button
                variant="primary"
                disabled={error || account.email.trim() === '' || addingAccount}
                onClick={async () => {
                  try {
                    setAddingAccount(true);
                    const url = Config.getConfigVar('createAdminAccountEndpoint');
                    const result = await axios.post<any, AxiosResponse<string>>(url, {
                      email: account.email,
                      roles,
                      pharamcyIDs: Object.keys(associatedPharmacies),
                    });
                    setAddingAccount(false);
                    history.push(`/admins/${result.data}`);
                  } catch {
                    setError(true);
                    setAddingAccount(false);
                  }
                }}
              >
                <FontAwesomeIcon icon="plus-circle" /> Add
              </Button>
            ) : (
              <>
                {hasRole(Role.Admin_Full) && (
                  <>
                    <Button
                      variant="secondary"
                      disabled={
                        initialRoles === roles &&
                        initialPharmacies === associatedPharmacies &&
                        initialGroups === associatedGroups
                      }
                      onClick={() => {
                        setRoles(initialRoles);
                        setAssociatedPharmacies(initialPharmacies);
                        setAssociatedGroups(initialGroups);
                      }}
                    >
                      <FontAwesomeIcon icon="history" /> Reset
                    </Button>{' '}
                    <Button
                      variant="primary"
                      disabled={
                        initialRoles === roles &&
                        initialPharmacies === associatedPharmacies &&
                        initialGroups === associatedGroups
                      }
                      onClick={() => setShowSaveModal(true)}
                    >
                      <FontAwesomeIcon icon="save" /> Save
                    </Button>
                  </>
                )}
              </>
            )}
          </p>
        </Col>
      </Row>
      <Tabs defaultActiveKey="details" id="pharmacy-tabs" className="my-3">
        <Tab eventKey="details" title="User Account" className="my-3 mx-2">
          <AccountTab
            account={account}
            fetchAccount={fetchAccount}
            setAccount={setAccount}
            username={username}
          />
        </Tab>
        <Tab eventKey="pharmacies" title="Pharmacy Access" className="my-3 mx-2">
          <PharmaciesTab
            account={account}
            associatedPharmacies={associatedPharmacies}
            setAssociatedPharmacies={setAssociatedPharmacies}
          />
        </Tab>
        <Tab eventKey="groups" title="Group Access" className="my-3 mx-2">
          <GroupsTab
            account={account}
            associatedGroups={associatedGroups}
            setAssociatedGroups={setAssociatedGroups}
          />
        </Tab>
        <Tab eventKey="roles" title="Permissions" className="my-3 mx-2">
          <RolesTab roles={roles} setRoles={setRoles} />
        </Tab>
        <Tab eventKey="audittrail" title="Audit Trail" className="my-3 mx-2">
          <AuditTrailTab query={`entityId=${account.id}`} />
        </Tab>
        <Tab eventKey="auditsmade" title="Changes Made" className="my-3 mx-2">
          <AuditTrailTab query={`accountId=${account.id}`} includeUser={false} />
        </Tab>
      </Tabs>
      <SaveModal
        showSaveModal={showSaveModal}
        setShowSaveModal={setShowSaveModal}
        saving={modalLoading}
        onSave={async () => {
          try {
            setModalLoading(true);
            const calls = [];
            if (initialRoles !== roles) {
              const url = Config.getConfigVar('updateAdminRolesEndpoint');
              calls.push(axios.patch(url, { accountId: account.id, roles }));
            }
            if (initialPharmacies !== associatedPharmacies) {
              const url = Config.getConfigVar('updateAdminPharmaciesEndpoint');
              calls.push(
                axios.patch(url, {
                  accountId: account.id,
                  pharmacyIds: Object.keys(associatedPharmacies),
                })
              );
            }
            if (initialGroups !== associatedGroups) {
              const url = Config.getConfigVar('updateAdminGroupsEndpoint');
              calls.push(
                axios.patch(url, {
                  accountId: account.id,
                  groupIds: Object.keys(associatedGroups),
                })
              );
            }
            await Promise.all(calls);
            setShowSaveModal(false);
            setModalLoading(false);
            fetchAccount();
          } catch (e) {
            setError(true);
          }
        }}
      />
    </>
  );
};
