export type Offer = {
    [key: string]: any;
    id: string;
    title: string;
    type: string;
    offerCode: string;
    liveOnDate: string;
    expiry: string;
    description: string;
    status?:string;
};

export const defaultOffer: Offer = {
    id: '',
    title: '',
    type: 'Code',
    offerCode: '',
    liveOnDate: new Date().toISOString(),
    expiry: new Date().toISOString(),
    description: '',
}