import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Config from '../config';
import { HelpFile } from '../types/HelpFile';

export default () => {
  const [files, setFiles] = useState<HelpFile[] | null>(null);
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [fileSharingType, setFileSharingType] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchFiles = async () => {
    try {
      setLoading(true);
      setError(false);
      const res = await axios.post<HelpFile[]>(
        Config.getConfigVar('helpFilesListEndpoint')
      );
      setFiles(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(false);
      const formData = new FormData();
      formData.append('file', uploadFile);
      formData.append('ownershipType', fileSharingType);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const res = await axios.post<HelpFile>(
        Config.getConfigVar('helpFilesUploadEndpoint'),
        formData,
        config
      );
      files?.push(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const deleteFile = async (fileId: string) => {
    try {
      setLoading(true);
      setError(false);
      const res = await axios.post<HelpFile[]>(
        Config.getConfigVar('helpFilesDeleteEndpoint'),
        {
          fileId,
        }
      );
      setFiles(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const resetForm = () => {
    setUploadFile(null);
    setFileSharingType('');
  };

  if (error) {
    return <Alert variant="danger">There was an error processing your request</Alert>;
  }

  return (
    <>
      <Row className="my-3">
        <Col>
          <h2>Help</h2>
        </Col>
      </Row>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Row className="mt-4 mb-3 mx-1">
            <Table size="sm">
              <thead>
                <tr>
                  <th>File</th>
                  <th>Sharing Type</th>
                  <th className="text-center">Download</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                {files && files.length > 0 ? (
                  files.map((file, i) => (
                    <tr key={i}>
                      <td>
                        <a href={file.filepath} target="_blank" rel="noopener noreferrer">
                          {file.filename}
                        </a>
                      </td>
                      <td>
                        {file.ownershipType ?? "All pharmacies"}
                      </td>
                      <td className="text-center">
                        <a href={file.filepath} target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon="cloud-download-alt" />
                        </a>
                      </td>
                      <td className="text-center">
                        <Button
                          variant="link"
                          onClick={async () => await deleteFile(file.id)}
                        >
                          <FontAwesomeIcon icon="trash-alt" />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>
                      <em>No help files found</em>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={4}>
                    <h3 className="mt-3">Upload a New File</h3>
                    <form onSubmit={onSubmit} className="mt-3 col-md-5">
                      <div className='row'>
                        <div className='col-md-4 ml-0 pl-0'>
                          <div className="form-check">
                            <input className="form-check-input"
                              type="radio"
                              name="onwershipType"
                              value="Owned"
                              checked={fileSharingType == 'Owned'}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFileSharingType('Owned');
                                }
                              }} />
                            <label className="form-check-label">
                              Owned
                            </label>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className="form-check">
                            <input className="form-check-input"
                              type="radio"
                              name="onwershipType"
                              value="Member"
                              checked={fileSharingType == 'Member'}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFileSharingType('Member');
                                }
                              }} />
                            <label className="form-check-label">
                              Member
                            </label>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className="form-check">
                            <input className="form-check-input"
                              type="radio"
                              name="onwershipType"
                              value="Independent"
                              checked={fileSharingType == 'Independent'}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFileSharingType('Independent');
                                }
                              }} />
                            <label className="form-check-label">
                              Independent
                            </label>
                          </div>
                        </div>
                        <label className='mt-2'>
                          <small>To be able to give permission for all pharmacies, the ownership type field should be empty.</small>
                        </label>
                      </div>
                      <div className='row mt-2'>
                        <div className="mb-3">
                          <label className="form-label">Add new file (max 25MB):</label>
                          <input className="ml-3" type="file" onChange={(e: any) => setUploadFile(e.target.files[0])} />
                        </div>
                      </div>
                      <div className="row float-right">
                        <Button variant="danger" className='mr-3' onClick={() => resetForm()}>
                          Reset
                        </Button>
                        <Button variant="primary" type="submit" disabled={uploadFile === null}>
                          Upload
                        </Button>
                      </div>
                    </form>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </>
      )}
    </>
  );
};
