import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Role } from '../../types/role';
import { hasRole } from '../../utils/auth';

export default ({ roles, setRoles }: { roles: Role[]; setRoles: (roles: Role[]) => void }) => {
  // Roles for user being edited
  const isFullAdmin = roles.includes(Role.Admin_Full);
  const hasGlobalRole = roles.includes(Role.Admin_Full) || roles.includes(Role.Admin_ReadOnly);

  return (
    <Form noValidate>
      <Form.Group as={Row} className="mb-0">
        <Form.Label column sm={2}>
          Pharmacies - Edit
        </Form.Label>
        <Col sm={7} className="mx-3">
          <Row>
            <Form.Check
              type="checkbox"
              disabled={hasGlobalRole || !hasRole(Role.Admin_Full)}
              checked={isFullAdmin || roles.includes(Role.Admin_Pharmacies_Edit)}
              onChange={() => {
                roles.includes(Role.Admin_Pharmacies_Edit)
                  ? setRoles(roles.filter((r) => r !== Role.Admin_Pharmacies_Edit))
                  : setRoles([...roles, Role.Admin_Pharmacies_Edit]);
              }}
            />
          </Row>
        </Col>
      </Form.Group>
      <Row className="mx-0 mb-4">
        <p className="font-weight-light">User can edit details for any pharmacy</p>
      </Row>
      <Form.Group as={Row} className="mb-0">
        <Form.Label column sm={2}>
          Pharmacies - Add
        </Form.Label>
        <Col sm={7} className="mx-3">
          <Row>
            <Form.Check
              type="checkbox"
              disabled={hasGlobalRole || !hasRole(Role.Admin_Full)}
              checked={isFullAdmin || roles.includes(Role.Admin_Pharmacies_Add)}
              onChange={() => {
                roles.includes(Role.Admin_Pharmacies_Add)
                  ? setRoles(roles.filter((r) => r !== Role.Admin_Pharmacies_Add))
                  : setRoles([...roles, Role.Admin_Pharmacies_Add]);
              }}
            />
          </Row>
        </Col>
      </Form.Group>
      <Row className="mx-0 mb-4">
        <p className="font-weight-light">User can add new pharmacies</p>
      </Row>
      <Form.Group as={Row} className="mb-0">
        <Form.Label column sm={2}>
          Users - Edit
        </Form.Label>
        <Col sm={7} className="mx-3">
          <Row>
            <Form.Check
              type="checkbox"
              disabled={hasGlobalRole || !hasRole(Role.Admin_Full)}
              checked={isFullAdmin || roles.includes(Role.Admin_Users_Edit)}
              onChange={() => {
                roles.includes(Role.Admin_Users_Edit)
                  ? setRoles(roles.filter((r) => r !== Role.Admin_Users_Edit))
                  : setRoles([...roles, Role.Admin_Users_Edit]);
              }}
            />
          </Row>
        </Col>
      </Form.Group>
      <Row className="mx-0 mb-4">
        <p className="font-weight-light">User can edit details for any app user</p>
      </Row>
      <Form.Group as={Row} className="mb-0">
        <Form.Label column sm={2}>
          Users - Delete
        </Form.Label>
        <Col sm={7} className="mx-3">
          <Row>
            <Form.Check
              type="checkbox"
              disabled={hasGlobalRole || !hasRole(Role.Admin_Full)}
              checked={isFullAdmin || roles.includes(Role.Admin_Users_Delete)}
              onChange={() => {
                roles.includes(Role.Admin_Users_Delete)
                  ? setRoles(roles.filter((r) => r !== Role.Admin_Users_Delete))
                  : setRoles([...roles, Role.Admin_Users_Delete]);
              }}
            />
          </Row>
        </Col>
      </Form.Group>
      <Row className="mx-0 mb-4">
        <p className="font-weight-light">
          User can access GDPR delete functionality for any app user
        </p>
      </Row>
      <Form.Group as={Row} className="mb-0">
        <Form.Label column sm={2}>
          Services - Edit
        </Form.Label>
        <Col sm={7} className="mx-3">
          <Row>
            <Form.Check
              type="checkbox"
              disabled={hasGlobalRole || !hasRole(Role.Admin_Full)}
              checked={isFullAdmin || roles.includes(Role.Admin_Services_Edit)}
              onChange={() => {
                roles.includes(Role.Admin_Services_Edit)
                  ? setRoles(roles.filter((r) => r !== Role.Admin_Services_Edit))
                  : setRoles([...roles, Role.Admin_Services_Edit]);
              }}
            />
          </Row>
        </Col>
      </Form.Group>
      <Row className="mx-0 mb-4">
        <p className="font-weight-light">
          User can edit or remove services available for pharmacies to display
        </p>
      </Row>
      <Form.Group as={Row} className="mb-0">
        <Form.Label column sm={2}>
          Offers - Edit
        </Form.Label>
        <Col sm={7} className="mx-3">
          <Row>
            <Form.Check
              type="checkbox"
              disabled={hasGlobalRole || !hasRole(Role.Admin_Full)}
              checked={isFullAdmin || roles.includes(Role.Admin_Offers_Edit)}
              onChange={() => {
                roles.includes(Role.Admin_Offers_Edit)
                  ? setRoles(roles.filter((r) => r !== Role.Admin_Offers_Edit))
                  : setRoles([...roles, Role.Admin_Offers_Edit]);
              }}
            />
          </Row>
        </Col>
      </Form.Group>
      <Row className="mx-0 mb-4">
        <p className="font-weight-light">
          User can edit offers available for pharmacies to use
        </p>
      </Row>
      <Form.Group as={Row} className="mb-0">
        <Form.Label column sm={2}>
          Admin - Read Only
        </Form.Label>
        <Col sm={7} className="mx-3">
          <Row>
            <Form.Check
              type="checkbox"
              disabled={roles.includes(Role.Admin_Full) || !hasRole(Role.Admin_Full)}
              checked={roles.includes(Role.Admin_ReadOnly)}
              onChange={() => {
                roles.includes(Role.Admin_ReadOnly)
                  ? setRoles([])
                  : setRoles([Role.Admin_ReadOnly]);
              }}
            />
          </Row>
        </Col>
      </Form.Group>
      <Row className="mx-0 mb-4">
        <p className="font-weight-light">
          User can view all the details in Back Office, but cannot edit, add or delete
        </p>
      </Row>
      <Form.Group as={Row} className="mb-0">
        <Form.Label column sm={2}>
          Admin - Full
        </Form.Label>
        <Col sm={7} className="mx-3">
          <Row>
            <Form.Check
              type="checkbox"
              disabled={roles.includes(Role.Admin_ReadOnly) || !hasRole(Role.Admin_Full)}
              checked={roles.includes(Role.Admin_Full)}
              onChange={() => {
                roles.includes(Role.Admin_Full) ? setRoles([]) : setRoles([Role.Admin_Full]);
              }}
            />
          </Row>
        </Col>
      </Form.Group>
      <Row className="mx-0 mb-4">
        <p className="font-weight-light">
          User has all above roles, as well as the ability to add more admin users to Back Office
        </p>
      </Row>
    </Form>
  );
};