import { PharmacyTabType } from './Pharmacy';
import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { OwnershipType } from '../../types/ownershipType';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types/role';
import { isPast } from 'date-fns';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group, defaultGroup } from '../../types/group';
import Config from '../../config';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { PMRTypes } from '../../types/pharmacy';

type DetailTabProps = PharmacyTabType & {
  pharmacyId: string;
  group: Group | null;
};

export default ({ pharmacy, setPharmacy, pharmacyId, group }: DetailTabProps) => {
  const history = useHistory();
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [creatingGroupError, setCreatingGroupError] = useState(false);

  const createGroup = async () => {
    setCreatingGroup(true);
    try {
      const newGroup = {
        ...defaultGroup,
        name: pharmacy.businessName,
        keyContact: pharmacy.keyContacts[0],
        telephoneNumber: pharmacy.telephoneNumber,
        email: pharmacy.email,
      };
      const url = Config.getConfigVar('updateGroupEndpoint');
      const result = await axios.put(url, { group: newGroup, pharmacyIds: [pharmacyId] });
      history.push(`/groups/${result.data?.id}`);
    } catch (e) {
      setCreatingGroupError(true);
    } finally {
      setCreatingGroup(false);
    }
  };

  return (
    <>
      <h4 className="mb-4">Details</h4>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Branch Name
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required
            placeholder="Branch Name"
            value={pharmacy.businessName}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                businessName: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      {group ? (
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Group
          </Form.Label>
          <Col sm={7}>
            <Form.Control type="text" disabled value={group.name} />
          </Col>
        </Form.Group>
      ) : (
        pharmacyId !== 'new' && (
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Group
            </Form.Label>
            <Col sm={7}>
              {creatingGroupError ? (
                'There was an error creating the new group'
              ) : (
                <Button
                  variant="link"
                  disabled={creatingGroup}
                  style={{ marginLeft: '-12px' }}
                  onClick={async () => {
                    await createGroup();
                  }}
                >
                  {creatingGroup ? 'Creating group' : 'Create group with same details'}
                </Button>
              )}
            </Col>
          </Form.Group>
        )
      )}
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Branch Number
        </Form.Label>
        <Col sm={2}>
          <Form.Control
            type="number"
            required
            placeholder="Branch Number"
            value={pharmacy.branchNumber}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                branchNumber: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Branch Code
        </Form.Label>
        <Col sm={2}>
          <Form.Control
            type="text"
            placeholder="Code"
            value={pharmacy.code}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                code: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          ODS Code
        </Form.Label>
        <Col sm={2}>
          <Form.Control
            type="text"
            placeholder="ODS Code"
            value={pharmacy.odsCode}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                odsCode: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Ownership Type
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            placeholder="Select type"
            value={pharmacy.ownershipType}
            onChange={(e: any) => setPharmacy({ ...pharmacy, ownershipType: e.target.value })}
          >
            <option value="">Select type</option>
            {Object.values(OwnershipType).map((t) => (
              <option value={t} key={t}>
                {t}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Address 1
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required
            placeholder="Address 1"
            value={pharmacy.address1}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                address1: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Address 2
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            placeholder="Address 2 (optional)"
            value={pharmacy.address2}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                address2: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Address 3
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            placeholder="Address 3 (optional)"
            value={pharmacy.address3}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                address3: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          City
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            placeholder="City (optional)"
            value={pharmacy.city}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                city: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Postcode
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required
            placeholder="Postcode"
            value={pharmacy.postcode}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                postcode: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Telephone
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required
            placeholder="Telephone"
            value={pharmacy.telephoneNumber}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                telephoneNumber: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Email
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="email"
            placeholder="Email"
            value={pharmacy.email}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                email: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      {pharmacyId === 'new' && (
        <Row className="mx-0 mb-4">
          <p className="font-weight-light text-danger">
            <FontAwesomeIcon icon="exclamation-triangle" className="mr-2" /> An account verification
            email will be sent to this address. This will allow login to the pharmacy portal for the
            pharmacy.
          </p>
        </Row>
      )}
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Key Contact
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            placeholder="Name"
            value={pharmacy.keyContacts.length ? pharmacy.keyContacts[0] : ''}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                keyContacts: [e.target.value],
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Distance Selling Pharmacy
        </Form.Label>
        <Col sm={7}>
          <Form.Check
            type="checkbox"
            checked={Boolean(pharmacy.deliveryOnly)}
            onChange={(e: any) => {
              setPharmacy({
                ...pharmacy,
                deliveryOnly: !pharmacy.deliveryOnly,
              });
            }}
          />
        </Col>
      </Form.Group>
      {hasRole(Role.Admin_Pharmacies_Toggle) && (
        <>
          <h4 className="my-4">App Visibility</h4>
          <Form.Group as={Row} className="mb-0">
            <Form.Label column sm={2}>
              Live
            </Form.Label>
            <Col sm={7} className="mx-3">
              <Row>
                <Form.Check
                  type="checkbox"
                  disabled={Boolean(pharmacy.liveOnDate)}
                  checked={Boolean(
                    (!pharmacy.liveOnDate && pharmacy.live) ||
                    (pharmacy.liveOnDate && isPast(new Date(pharmacy.liveOnDate)))
                  )}
                  onChange={(e: any) => {
                    setPharmacy({
                      ...pharmacy,
                      live: !pharmacy.live,
                    });
                  }}
                />
              </Row>
            </Col>
          </Form.Group>
          <Row className="mx-0 mb-4">
            <p className="font-weight-light">
              When set, this means that users in the app can select this pharmacy as their nominated
              pharmacy
            </p>
          </Row>
          <Form.Group as={Row} className="mb-0">
            <Form.Label column sm={2}>
              Live on Date
            </Form.Label>
            {pharmacy.liveOnDate ? (
              <Col sm={7} className="mx-3">
                <Row>
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={new Date(pharmacy.liveOnDate)}
                    onChange={(date) => {
                      if (date) {
                        setPharmacy({
                          ...pharmacy,
                          liveOnDate: date.toISOString(),
                        });
                      }
                    }}
                  />
                </Row>
                <Row>
                  <Button
                    variant="link"
                    onClick={() =>
                      setPharmacy({
                        ...pharmacy,
                        liveOnDate: null,
                      })
                    }
                  >
                    Remove date restriction
                  </Button>
                </Row>
              </Col>
            ) : (
              <Button
                variant="link"
                onClick={() =>
                  setPharmacy({
                    ...pharmacy,
                    live: false,
                    liveOnDate: new Date().toISOString(),
                  })
                }
              >
                Set date
              </Button>
            )}
          </Form.Group>
          <Row className="mx-0 mb-4">
            <p className="font-weight-light">
              Use this option if you want to set the date that a pharmacy becomes live for a user.
              Remove the date if you want to use the 'Live' checkbox on its own.
            </p>
          </Row>
          <Form.Group as={Row} className="mb-0">
            <Form.Label column sm={2}>
              Visible on Map
            </Form.Label>
            <Col sm={7} className="mx-3">
              <Row>
                <Form.Check
                  type="checkbox"
                  checked={pharmacy.visibleOnMap}
                  onChange={(e: any) => {
                    setPharmacy({
                      ...pharmacy,
                      visibleOnMap: !pharmacy.visibleOnMap,
                    });
                  }}
                />
              </Row>
            </Col>
          </Form.Group>
          <Row className="mx-0 mb-4">
            <p className="font-weight-light">
              When set, this pharmacy will appear on maps in the app
            </p>
          </Row>
          <Form.Group as={Row} className="mb-0">
            <Form.Label column sm={2}>
              Allow Manual Reordering
            </Form.Label>
            <Col sm={7} className="mx-3">
              <Row>
                <Form.Check
                  type="checkbox"
                  checked={Boolean(pharmacy.allowManualReordering)}
                  onChange={(_e: any) => {
                    setPharmacy({
                      ...pharmacy,
                      allowManualReordering: !pharmacy.allowManualReordering,
                    });
                  }}
                />
              </Row>
            </Col>
          </Form.Group>
          <Row className="mx-0 mb-4">
            <p className="font-weight-light">
              When set, this allows users to re-order any previous item, regardless of IM1
              integration. This is a legacy setting for pharmacies that transitioned from pilot to
              the live system to ensure their users could still make orders in the interim.
            </p>
          </Row>
          <Form.Group as={Row} className="mb-0">
            <Form.Label column sm={2}>
              Enable PMR Automation
            </Form.Label>
            <Col sm={7} className="mx-3">
              <Row>
                <Form.Check
                  type="checkbox"
                  checked={Boolean(pharmacy.pmrConfig?.enabled)}
                  onChange={(_e: any) => {
                    if (!pharmacy.pmrConfig?.enabled) {
                      setPharmacy({
                        ...pharmacy,
                        isAutoReadyForCollectionEnabled: false,
                        readyForCollectionCheckingDuration: 0,
                        isAutoCollectionEnabled: false,
                        autoCollectionCheckinDuration: 0,
                        pmrConfig: {
                          enabled: true,
                          provider: ''
                        },
                      });
                    } else {
                      setPharmacy({
                        ...pharmacy,
                        pmrConfig: null,
                      });
                    }
                  }}
                />
              </Row>
            </Col>
          </Form.Group>
          <Row className={"mx-0 " + (!pharmacy.pmrConfig?.enabled ? 'mb-4' : '')}>
            <p className="font-weight-light">
              If you enable PMR Automation, the system automation will be disabled automatically. Both can not be used together.
            </p>
          </Row>
          {
            pharmacy.pmrConfig?.enabled && (
              <>
                <Form.Group as={Row} className="mb-4">
                  <Form.Label column sm={2}>
                    Please select a PMR.
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      as="select"
                      required={pharmacy.pmrConfig?.enabled}
                      placeholder="Select type"
                      value={pharmacy.pmrConfig?.provider ?? ''}
                      onChange={(e: any) => {
                        setPharmacy({
                          ...pharmacy,
                          pmrConfig: {
                            ...pharmacy.pmrConfig!,
                            provider: e.target.value
                          }
                        })
                      }
                      }
                    >
                      <option value="">Select a PMR</option>
                      {Object.values(PMRTypes).map((t) => (
                        <option value={t} key={t}>
                          {t}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </>
            )
          }
          <Form.Group as={Row} className="mb-0">
            <Form.Label column sm={2}>
              Enable Auto Ready for Collection
            </Form.Label>
            <Col sm={7} className="mx-3">
              <Row>
                <Form.Check
                  type="checkbox"
                  checked={Boolean(pharmacy.isAutoReadyForCollectionEnabled)}
                  onChange={(_e: any) => {
                    setPharmacy({
                      ...pharmacy,
                      isAutoReadyForCollectionEnabled: !pharmacy.isAutoReadyForCollectionEnabled,
                      pMRConfig: null
                    });
                  }}
                />
              </Row>
            </Col>
          </Form.Group>
          <Row className="mx-0 mb-4">
            <p className="font-weight-light">
              When a with gp status is set, if the order doesn't move manually in the determined time, the system changes the status automatically to 'Ready for Collection'.
            </p>
          </Row>
          {
            pharmacy.isAutoReadyForCollectionEnabled && (
              <>
                <Form.Group as={Row} className="mb-0">
                  <Form.Label column sm={2}>
                    Ready for Collection Duration (Day)
                  </Form.Label>
                  <Col sm={7} className="mx-3">
                    <Row>
                      <Form.Control
                        type="number"
                        placeholder="Day Count"
                        className="col-md-4 mb-2"
                        value={pharmacy?.readyForCollectionCheckingDuration ? pharmacy.readyForCollectionCheckingDuration : 0}
                        onChange={(e: any) => {
                          setPharmacy({
                            ...pharmacy,
                            readyForCollectionCheckingDuration: e.target.value
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </Form.Group>
                <Row className="mx-0 mb-4">
                  <p className="font-weight-light">
                    This is an optional field. If you don't set a value, the system uses a default one which is defined in the application configurations.
                  </p>
                </Row>
              </>
            )
          }
          <Form.Group as={Row} className="mb-0">
            <Form.Label column sm={2}>
              Enable Auto Collection
            </Form.Label>
            <Col sm={7} className="mx-3">
              <Row>
                <Form.Check
                  type="checkbox"
                  checked={Boolean(pharmacy.isAutoCollectionEnabled)}
                  onChange={(_e: any) => {
                    setPharmacy({
                      ...pharmacy,
                      isAutoCollectionEnabled: !pharmacy.isAutoCollectionEnabled,
                      pMRConfig: null
                    });
                  }}
                />
              </Row>
            </Col>
          </Form.Group>
          <Row className="mx-0 mb-4">
            <p className="font-weight-light">
              When ready for collection status is set, if the patient doesn't collect the order the system changes the status automatically to 'collected' without any notification.
            </p>
          </Row>
          {
            pharmacy.isAutoCollectionEnabled && (
              <>
                <Form.Group as={Row} className="mb-0">
                  <Form.Label column sm={2}>
                    Auto Collection Duration (Day)
                  </Form.Label>
                  <Col sm={7} className="mx-3">
                    <Row>
                      <Form.Control
                        type="number"
                        placeholder="Day Count"
                        className="col-md-4 mb-2"
                        value={pharmacy?.autoCollectionCheckinDuration ? pharmacy.autoCollectionCheckinDuration : 0}
                        onChange={(e: any) => {
                          setPharmacy({
                            ...pharmacy,
                            autoCollectionCheckinDuration: e.target.value,
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </Form.Group>
                <Row className="mx-0 mb-4">
                  <p className="font-weight-light">
                    This is an optional field. If you don't set a value, the system uses a default one which is defined in the application configurations.
                  </p>
                </Row>
              </>
            )
          }
        </>
      )}
    </>
  );
};
