import axios from 'axios';
import { store } from '../index';
import Config from '../config';
import { setRefreshToken, signOut } from '../actions/user';
import { getRoles, hasPharmacyAccess as hasPharmacyAccessSelector } from '../selectors/user';
import { Role } from '../types/role';

export async function addAxiosAuthInterceptors() {
  // Add access token to all requests
  axios.interceptors.request.use(async request => {
    request.headers.Authorization = `Bearer ${sessionStorage.getItem('access_token')}`;
    return request;
  });

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;

      if (
        !error ||
        !error.response ||
        (error.response.status !== 200 &&
          originalRequest.url === Config.getConfigVar('refreshTokenEndpoint'))
      ) {
        store.dispatch(signOut());
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refresh_token = sessionStorage.getItem('refresh_token');

        const res = await axios.post(Config.getConfigVar('refreshTokenEndpoint'), {
          refresh_token
        });

        if (res.status === 200) {
          // Update tokens in stores
          storeAccessToken(res.data.access_token);
          store.dispatch(setRefreshToken(res.data.refresh_token));
          axios.defaults.headers.common.Authorization = 'Bearer ' + res.data.access_token;
          return axios(originalRequest);
        }
      }
      
      return Promise.reject(error);
    }
  );
}

export async function storeAccessToken(access_token: string) {
  sessionStorage.setItem('access_token', access_token);
}

export async function clearAccessToken() {
  sessionStorage.removeItem('access_token');
}

export function hasRole(role: Role): boolean {
  const roles = getRoles(store.getState());
  // Admin_full overwrites all
  return roles.includes(role) || roles.includes(Role.Admin_Full);
}

export function hasPharmacyAccess(): boolean {
  return hasPharmacyAccessSelector(store.getState()) 
    || hasRole(Role.Admin_Pharmacies_Add)
    || hasRole(Role.Admin_Pharmacies_Edit)
    || hasRole(Role.Admin_Pharmacies_Toggle)
    || hasRole(Role.Admin_ReadOnly);
}

export function hasPharmacyEdit(): boolean {
  return hasPharmacyAccessSelector(store.getState()) 
    || hasRole(Role.Admin_Pharmacies_Add)
    || hasRole(Role.Admin_Pharmacies_Edit);
}
