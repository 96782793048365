import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import logo from '../assets/logo.png';
import axios from 'axios';
import Config from '../config';
import { appInsights } from '..';
import { addAxiosAuthInterceptors, storeAccessToken } from '../utils/auth';
import { Role } from '../types/role';

type SignInProps = {
  signIn: (username: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  addPermissions: (pharmacyIDs: string[], roles: Role[]) => void;
  message: string | null;
  clearMessage: () => void;
};

export default ({ signIn, setRefreshToken, addPermissions, message, clearMessage }: SignInProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [signInFail, setSignInFail] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setSignInLoading(true);
      try {
        event.preventDefault();
        event.stopPropagation();

        const res = await axios.post(Config.getConfigVar('signInEndpoint'), {
          username,
          password,
        });

        storeAccessToken(res.data.access_token);
        setRefreshToken(res.data.refresh_token);
        await addAxiosAuthInterceptors();

        if (!res.data?.access_token || !res.data?.refresh_token) {
          throw Error('unable to retrieve tokens');
        }

        const permissions = await axios.get(Config.getConfigVar('userPermissionsEndpoint'));

        if (!permissions.data?.roles || !permissions.data?.pharmacyIDs) {
          throw Error('Incorrect permissions response');
        }

        addPermissions(permissions.data.pharmacyIDs, permissions.data.roles);

        appInsights.trackEvent({ name: 'Sign in', properties: { username } });
        signIn(username);
        setSignInLoading(false);
        clearMessage();
      } catch (e) {
        appInsights.trackEvent({ name: 'Sign in failure', properties: { username } });
        appInsights.trackException(e);
        setSignInLoading(false);
        setSignInFail(true);

        event.preventDefault();
        event.stopPropagation();
      }
    }
    setValidated(true);
  };

  return (
    <SignInForm noValidate validated={validated} onSubmit={async (e: any) => await handleSubmit(e)}>
      <Logo src={logo} height={60} width={312} alt="Avicenna Logo" />
      {message && <Alert variant="primary">{message}</Alert>}
      <Form.Group>
        <Form.Label>Email address</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Enter email"
          value={username}
          onChange={(e: any) => setUsername(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">Please enter your email</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">Please enter your password</Form.Control.Feedback>
      </Form.Group>
      {signInFail && <p>Incorrect username and/or password. Please try again.</p>}
      <Button variant="primary" type="submit" disabled={signInLoading}>
        {signInLoading ? 'Signing in...' : 'Sign In'}
      </Button>
    </SignInForm>
  );
};

const SignInForm = styled(Form)`
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  margin-top: 8vh;
`;

const Logo = styled.img`
  margin-left: -10px;
  margin-bottom: 40px;
`;
