import keyMirror from 'keymirror';

export const actionTypes = keyMirror({
	SET_MESSAGE: null,
	CLEAR_MESSAGE: null
});

export const setMessage = (message: string): Action => ({
  type: actionTypes.SET_MESSAGE,
  payload: { message }
});

export const clearMessage = (): Action => {
  return {
    type: actionTypes.CLEAR_MESSAGE
  };
};