import { combineReducers } from 'redux';
import userReducer, { userStateType } from './user';
import signInReducer, { signInStateType } from './signIn';

export default combineReducers({ 
  user: userReducer,
  signIn: signInReducer
});

export type GlobalState = {
  user: userStateType;
  signIn: signInStateType;
};
