import { connect } from 'react-redux';
import { signIn, setRefreshToken, addPermissions } from '../actions/user';
import { getSignInMessage } from '../selectors/signIn';
import { clearMessage } from '../actions/signIn';
import SignIn from './SignIn';
import { Role } from '../types/role';
import { GlobalState } from '../reducers';

const mapStateToProps = (state: GlobalState) => ({
  message: getSignInMessage(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  signIn: (username: string) => dispatch(signIn(username)),
  setRefreshToken: (refreshToken: string) => dispatch(setRefreshToken(refreshToken)),
  addPermissions: (pharamcyIDs: string[], roles: Role[]) =>
    dispatch(addPermissions(pharamcyIDs, roles)),
  clearMessage: () => dispatch(clearMessage())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
