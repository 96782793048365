import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import Config from './config';
import { createStore, applyMiddleware, Store } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { loadUserState, saveUserState } from './utils/storage';
import { addAxiosAuthInterceptors } from './utils/auth';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";

let appInsights: ApplicationInsights, store: Store;

const PreLoadDiv = styled.div`
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  width: 30px
`;

ReactDOM.render(
  <PreLoadDiv>
    <Spinner variant="primary" animation="border" role="status" />
  </PreLoadDiv>,
  document.getElementById('root')
);

Config.getConfig().then(() => {
  const persistedState = loadUserState();

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: Config.getConfigVar('backOfficeApplicationInsightsKey')
    }
  });
  //if (process.env.NODE_ENV === 'production') {
    appInsights.loadAppInsights();
  //}
  appInsights.trackPageView();
  appInsights.trackEvent({ name: 'Back office loaded' });

  store = createStore(rootReducer, persistedState, applyMiddleware(thunk));

  if (persistedState) {
    addAxiosAuthInterceptors();
  }

  store.subscribe(() => {
    saveUserState(store.getState());
  });

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});

export { appInsights, store };