import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Config from '../../config';
import { PatientTabProps } from './Patient';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import format from 'date-fns/format';
import compareDesc from 'date-fns/compareDesc';
import { PatientIntegrationType } from '../../types/patient';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const timestampFormat = 'dd/MM/yyyy HH:mm:ss.SSS';

const formatIM1Call = (url: string) => {
  if(url) {
      var i = url.indexOf('?');
    return url.substring(0, i !== -1 ? i - 1 : url.length);
  }
};

export default ({ patient }: PatientTabProps) => {
  if (!patient) {
    return null;
  }

  if (!patient.patientIntegration) {
    return (
      <Row className="my-3">
        <Col>
          <em>Patient not linked to IM1</em>
        </Col>
      </Row>
    );
  }

  const patientAuditUrl = Config.getConfigVar('patientIM1AuditsEndpoint');
  const correlatedAuditUrl = Config.getConfigVar('correlationIM1AuditsEndpoint');

  const [audits, setAudits] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [correlationId, setCorrelationId] = useState('');
  const [correlatedAudits, setCorrelatedAudits] = useState<any[]>([]);
  const [copied, setCopied] = useState(false)

  const fetchAudits = async () => {
    try {
      setLoading(true);
      const result = await axios.get(
        `${patientAuditUrl}/${patient.patientIntegration?.thirdPartyPatientId}`
      );
      setAudits(result.data.data);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  const fetchCorrelatedAudits = async () => {
    if (correlationId !== '') {
      try {
        setModalError(false);
        setModalLoading(true);
        const result = await axios.get(`${correlatedAuditUrl}/${correlationId}`);
        setCorrelatedAudits(result.data.data);
        setModalLoading(false);
      } catch (e) {
        setModalError(true);
        setModalLoading(false);
      }
    }
  };

  const copyToClipboard = (text: string) =>{
    navigator.clipboard.writeText(text).then(()=>{
      setCopied(true);
      setTimeout(() => {
        setCopied(false)
      }, 500);
    });
  }

  useEffect(() => {
    fetchAudits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCorrelatedAudits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correlationId]);

  const getModalBody = () => {
    if (!showModal) {
      return null;
    }

    if (modalError) {
      return 'There was an error processing your request';
    }

    if (modalLoading) {
      return <Spinner animation="border" />;
    }

    return (
      <Table size="sm">
        <thead>
          <tr>
            <th>Time</th>
            <th>IM1 Call</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {correlatedAudits &&
            correlatedAudits
              .sort((a, b) => compareDesc(new Date(a.timestamp), new Date(b.timestamp)))
              .map((audit, i) => (
                <tr key={i}>
                  <td>{format(new Date(audit.timestamp), timestampFormat)}</td>
                  <td>{formatIM1Call(audit.url)}</td>
                  <td>{audit.httpStatusCode}</td>
                </tr>
              ))}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <h4 className="mb-4">Audit Trail</h4>
      <p>
        <strong>Integration: </strong>
        {PatientIntegrationType[patient.patientIntegration.integrationType]}
      </p>
      <Row>
        <Col sm={8}>
          <Table size="sm">
            <thead>
              <tr>
                <th>Time</th>
                <th>IM1 Call</th>
                <th>Correlation ID</th>
                <th/>
              </tr>
            </thead>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <tbody>
                {audits
                  .sort((a, b) => compareDesc(new Date(a.timestamp), new Date(b.timestamp)))
                  .map((audit, i) => (
                    <tr key={i}>
                      <td>
                        {format(new Date(audit.timestamp), timestampFormat)}
                      </td>
                      <td>{formatIM1Call(audit.url)}</td>
                      <td
                        onClick={() => {
                          setShowModal(true);
                          setCorrelationId(audit.correlationId);
                        }}
                      >
                        <Button variant="link">{audit.correlationId}</Button>
                      </td>
                      <td
                        onClick={() => {
                          copyToClipboard(`${audit.correlationId}`)
                        }}
                      >
                        <OverlayTrigger
                          placement='right'
                          overlay={
                            <Tooltip id="tooltip-clipboard">
                              {copied ? 'Copied!': 'Copy to clipboard'}
                            </Tooltip>
                          }
                        >
                            <Button variant="link">
                              <FontAwesomeIcon icon="clipboard" />
                            </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>
        </Col>
      </Row>
      <Modal
        show={showModal}
        size="lg"
        onHide={() => {
          setShowModal(false);
          setLoading(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Correlated Events</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getModalBody()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
