import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import logo from '../assets/logo.png';
import axios from 'axios';
import Config from '../config';
import Alert from 'react-bootstrap/Alert';
import { useParams, useHistory } from 'react-router-dom';
import qs from 'qs';

const capitalLetter = /[A-Z]/;
const numberCharacter = /\d/;
const specialCharacter = /[^A-Za-z0-9]/;
const minLengthForPassword = 8;

type CreatePasswordProps = {
  setMessage: (message: string) => void;
};

export default ({ setMessage }: CreatePasswordProps) => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const redirectToPortal = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    .redirectToPortal;

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (password !== confirmPassword) {
      setError('Passwords do not match');
    } else if (
      !capitalLetter.test(password) ||
      !numberCharacter.test(password) ||
      !specialCharacter.test(password) ||
      password.length < minLengthForPassword
    ) {
      setError(
        'Password must be at least 8 characters long and contain at least one uppercase character and symbol'
      );
    } else {
      setError('');
      setLoading(true);
      try {
        event.preventDefault();
        event.stopPropagation();

        await axios.post(Config.getConfigVar('createAdminB2CUserEndpoint'), {
          verificationToken: token,
          password,
        });

        setMessage('Account created - please sign in');

        // redirect to sign in
        // no env name = prod so redirect to proper URL
        if (redirectToPortal === 'True') {
          window.location.href = `${Config.getConfigVar('portalAppDomain')}?fromBackOffice=True`;
        } else if (process.env.REACT_APP_ENV_LABEL) {
          history.push('/');
        } else {
          window.location.href = 'https://backoffice.managemymeds.co.uk';
        }
      } catch (e) {
        console.warn(e);
        setLoading(false);
        setError('There was an error processing your request');

        event.preventDefault();
        event.stopPropagation();
      }
    }
    setValidated(true);
  };

  return (
    <PasswordForm
      noValidate
      validated={validated}
      onSubmit={async (e: any) => await handleSubmit(e)}
    >
      <Logo src={logo} height={60} width={312} alt="Avicenna Logo" />
      <p>
        <em>
          Password must be at least 8 characters long and contain at least one uppercase character
          and symbol
        </em>
      </p>
      {error !== '' && <Alert variant="danger">{error}</Alert>}
      <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">Please enter a password</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Confirm password"
          value={confirmPassword}
          onChange={(e: any) => setConfirmPassword(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">Please repeat your password</Form.Control.Feedback>
      </Form.Group>
      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? 'Loading...' : 'Create Password'}
      </Button>
    </PasswordForm>
  );
};

const PasswordForm = styled(Form)`
  width: 100%;
  max-width: 500px;
  padding: 15px;
  margin: auto;
  margin-top: 8vh;
`;

const Logo = styled.img`
  margin-left: 80px;
  margin-bottom: 40px;
`;
