import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../../config';
import Group from './Group';
import { Group as GroupType } from '../../types/group';
import Form from 'react-bootstrap/Form';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types/role';

export default () => {
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [search, setSearch] = useState('');
  const [sortBy] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filterParam, setFilterParam] = useState('includeInactive=false&includeActive=true');

  const fetchGroups = async () => {
    try {
      setLoading(true);
      const groupsUrl = Config.getConfigVar('getGroupsEndpoint');
      const pharmResult = await axios.get(`${groupsUrl}?${filterParam}`);
      setGroups(pharmResult.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line
  }, [filterParam]);

  const dateFormat = Config.getConfigVar('dateFormat');

  const groupSearch = (group: GroupType) => {
    const searchString = search.toLocaleLowerCase().trim();

    return (
      group.name.toLocaleLowerCase().includes(searchString) ||
      group.email.toLocaleLowerCase().includes(searchString) ||
      group.telephoneNumber.toLocaleLowerCase().includes(searchString)
    );
  };

  let content = null;

  const getSortIcon = () => {
    if (sortDirection === 'asc') {
      return <FontAwesomeIcon icon="sort-up" onClick={() => setSortDirection('desc')} />;
    }
    return <FontAwesomeIcon icon="sort-down" onClick={() => setSortDirection('asc')} />;
  };

  if (loading) {
    content = <Spinner animation="border" variant="primary" />;
  } else if (error) {
    content = <Alert variant="danger">Error loading groups</Alert>;
  } else {
    const filteredGroups = groups
      .sort((a, b) => {
        const asc = a[sortBy] === b[sortBy] ? 0 : a[sortBy] < b[sortBy] ? -1 : 1;
        return sortDirection === 'asc' ? asc : asc * -1;
      })
      .filter(groupSearch);

    content =
      filteredGroups.length === 0 ? (
        <em>No groups found</em>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Name {getSortIcon()}</th>
              <th>Email</th>
              <th>Tel No.</th>
              <th>Live</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {filteredGroups.map((group) => {
              return (
                <tr key={group.id}>
                  <td>
                    <Link to={`${match.url}/${group.id}`}>{group.name}</Link>
                  </td>
                  <td>{group.email}</td>
                  <td>{group.telephoneNumber}</td>
                  <td>{group.live && <FontAwesomeIcon icon="check-circle" />}</td>
                  <td>
                    <Link to={`${match.url}/${group.id}`}>
                      <FontAwesomeIcon icon="edit" />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
  }

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:groupId`}>
          <Group />
        </Route>
        <Route path={match.path}>
          <Row className="my-3">
            <Col sm={3}>
              <h2>Groups</h2>
            </Col>
            <Col sm={6}>
              <Form>
                <Form.Control
                  type="text"
                  placeholder="Search by group name, email, telephone number"
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}
                />
              </Form>
            </Col>
            {hasRole(Role.Admin_Pharmacies_Add) && (
              <Col sm={3}>
                <p className="text-right">
                  <Link to={`${match.url}/new`}>
                    <Button variant="primary">
                      <FontAwesomeIcon icon="plus-circle" /> Add new group
                    </Button>
                  </Link>
                </p>
              </Col>
            )}
          </Row>
          <Row className="mx-1 mb-4">
            <Form>
              <Form.Control
                as="select"
                value={filterParam}
                onChange={(e: any) => setFilterParam(e.target.value)}
              >
                <option value={'includeInactive=true&includeActive=true'}>All</option>
                <option value={'includeInactive=false&includeActive=true'}>Active only</option>
                <option value={'includeInactive=true&includeActive=false'}>Inactive only</option>
              </Form.Control>
            </Form>
          </Row>
          <Row className="mx-1">{content}</Row>
        </Route>
      </Switch>
    </>
  );
};
