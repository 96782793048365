import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Config from '../config';
import axios from 'axios';
import { Account } from '../types/account';

type ModalProps = {
  account: Account;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  fetchAccount: Function;
};

export default ({
  account,
  showModal,
  setShowModal,
  fetchAccount,
}: ModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setLoading(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Account Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">There was an error updating the account status</Alert>}
        <p>
          <strong>
            {account.email} currently has a status of {account.accountStatus}
          </strong>
          .
        </p>
        <p>This means {getAccountStatusText(account.accountStatus)}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        {loading ? (
          <Button variant="primary" disabled>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <span className="sr-only">
              {account.accountStatus === 'Deactivated' ? 'Re-activating' : 'Deactivating'}
            </span>
          </Button>
        ) : (
          <Button
            variant="danger"
            onClick={async () => {
              try {
                setLoading(true);
                const url = Config.getConfigVar('updateAccountStatusEndpoint');
                await axios.post(url, {
                  accountId: account.id,
                  status: account.accountStatus === 'Deactivated' ? 'Active' : 'Deactivated',
                });
                setShowModal(false);
                fetchAccount();
              } catch (e) {
                setError(true);
              }
              setLoading(false);
            }}
          >
            {account.accountStatus === 'Deactivated' ? 'Re-activate' : 'Deactivate'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const getAccountStatusText = (accountStatus: string): string => {
  switch (accountStatus.toLocaleLowerCase()) {
    case 'active':
      return 'the account is fully verified and the signup process completed.';
    case 'verified':
      return 'the email address assoicated with the account has been verified, but the full signup process has not been finished.';
    case 'created':
      return 'signup has been started but the email address has not yet been verified.';
    case 'deactivated':
      return 'the account has been blocked from accessing the application.';
    default:
      return 'the account is in an unknown status.';
  }
};