import keyMirror from 'keymirror';
import { Role } from '../types/role';

export const actionTypes = keyMirror({
  SIGN_IN: null,
  SIGN_OUT: null,
  SET_REFRESH_TOKEN: null,
  ADD_PERMISSIONS: null
});

export const signIn = (username: string): Action => ({
  type: actionTypes.SIGN_IN,
  payload: { username }
});

export const setRefreshToken = (refreshToken: string): Action => ({
  type: actionTypes.SET_REFRESH_TOKEN,
  payload: { refreshToken }
});

export const addPermissions = (pharmacyIDs: string[], roles: Role[]): Action => ({
  type: actionTypes.ADD_PERMISSIONS,
  payload: { pharmacyIDs, roles }
});

export const signOut = (): Action => {
  return {
    type: actionTypes.SIGN_OUT
  };
};