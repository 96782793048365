import React, { ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import navLogo from '../assets/nav-logo.png';
import SignIn from './SignIn.container';
import { Role } from '../types/role';
import { hasPharmacyAccess, hasRole } from '../utils/auth';

type AppPageProps = {
  username: string | null;
  signOut: () => void;
  children: ReactNode;
};

export default ({ username, signOut, children }: AppPageProps) => {
  if (!username) {
    return <SignIn />;
  }

  return (
    <>
      <StyledNavbar variant="dark" expand="lg" fixed="top">
        <Navbar.Brand href="#/pharmacies">
          <StyledNavLogo src={navLogo} height={30} width={30} alt="Avicenna Logo" />
          ManageMyMeds Back Office{' '}
          {process.env.REACT_APP_ENV_LABEL ? `(${process.env.REACT_APP_ENV_LABEL})` : ''}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {hasRole(Role.Admin_ReadOnly) && (
              <NavLink className="nav-link d-lg-none" activeClassName="active" to="/dashboard">
                Dashboard
              </NavLink>
            )}
            {hasRole(Role.Admin_ReadOnly) && (
              <NavLink className="nav-link d-lg-none" activeClassName="active" to="/groups">
                Groups
              </NavLink>
            )}
            {hasPharmacyAccess() && (
              <NavLink className="nav-link d-lg-none" activeClassName="active" to="/pharmacies">
                Pharmacies
              </NavLink>
            )}
            {hasRole(Role.Admin_ReadOnly) && (
              <NavLink className="nav-link d-lg-none" activeClassName="active" to="/admins">
                Admin Accounts
              </NavLink>
            )}
            {(hasRole(Role.Admin_ReadOnly) ||
              hasRole(Role.Admin_Users_Delete) ||
              hasRole(Role.Admin_Users_Edit)) && (
              <>
                <NavLink className="nav-link d-lg-none" activeClassName="active" to="/accounts">
                  User Accounts
                </NavLink>
                <NavLink className="nav-link d-lg-none" activeClassName="active" to="/patients">
                  Patients
                </NavLink>
              </>
            )}
            {(hasRole(Role.Admin_ReadOnly) || hasRole(Role.Admin_Services_Edit)) && (
              <NavLink className="nav-link d-lg-none" activeClassName="active" to="/services">
                Services
              </NavLink>
            )}
            {(hasRole(Role.Admin_ReadOnly) || hasRole(Role.Admin_Offers_Edit)) &&
              process.env.REACT_APP_FEATURE_OFFERS === 'true' && (
                <NavLink className="nav-link d-lg-none" activeClassName="active" to="/offers">
                  Offers
                </NavLink>
              )}
            <NavDropdown title={username} id="basic-nav-dropdown" alignRight>
              <NavDropdown.Item onClick={signOut}>Sign Out</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </StyledNavbar>
      <Container fluid>
        <Row>
          <SidebarCol lg={2} className="d-none d-lg-block">
            <SidebarDiv>
              <Nav className="flex-column py-3">
                {hasRole(Role.Admin_ReadOnly) && (
                  <StyledNavLink className="nav-link" activeClassName="active" to="/dashboard">
                    Dashboard
                  </StyledNavLink>
                )}
                {hasRole(Role.Admin_ReadOnly) && (
                  <StyledNavLink className="nav-link" activeClassName="active" to="/groups">
                    Groups
                  </StyledNavLink>
                )}
                {hasPharmacyAccess() && (
                  <StyledNavLink className="nav-link" activeClassName="active" to="/pharmacies">
                    Pharmacies
                  </StyledNavLink>
                )}
                {hasRole(Role.Admin_ReadOnly) && (
                  <StyledNavLink className="nav-link" activeClassName="active" to="/admins">
                    Admin Accounts
                  </StyledNavLink>
                )}
                {(hasRole(Role.Admin_ReadOnly) ||
                  hasRole(Role.Admin_Users_Delete) ||
                  hasRole(Role.Admin_Users_Edit)) && (
                  <>
                    <StyledNavLink className="nav-link" activeClassName="active" to="/accounts">
                      User Accounts
                    </StyledNavLink>
                    <StyledNavLink className="nav-link" activeClassName="active" to="/patients">
                      Patients
                    </StyledNavLink>
                  </>
                )}
                {(hasRole(Role.Admin_ReadOnly) || hasRole(Role.Admin_Services_Edit)) && (
                  <StyledNavLink className="nav-link" activeClassName="active" to="/services">
                    Services
                  </StyledNavLink>
                )}
                {(hasRole(Role.Admin_ReadOnly) || hasRole(Role.Admin_Offers_Edit)) &&
                  process.env.REACT_APP_FEATURE_OFFERS === 'true' && (
                    <StyledNavLink className="nav-link" activeClassName="active" to="/offers">
                      Offers
                    </StyledNavLink>
                  )}
                {hasRole(Role.Admin_ReadOnly) && (
                  <StyledNavLink className="nav-link" activeClassName="active" to="/help">
                    Help Files
                  </StyledNavLink>
                )}
                {(hasRole(Role.Admin_Full) || hasRole(Role.Admin_ReadOnly)) && (
                  <StyledNavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/booking-reports"
                  >
                    Booking Reports
                  </StyledNavLink>
                )}
              </Nav>
            </SidebarDiv>
          </SidebarCol>
          <MainCol lg={10} className="px-4">
            <div className="py-4">{children}</div>
          </MainCol>
        </Row>
      </Container>
    </>
  );
};

const SidebarCol = styled(Col)`
  background-color: #eee;
  border-right: 1px solid #ddd;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 56px 0 0;
  z-index: 100;
`;

const SidebarDiv = styled.div`
  height: calc(100vh - 56px);
  position: sticky;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainCol = styled(Col)`
  margin-left: auto !important;
  padding-top: 56px;
`;

const StyledNavLink = styled(NavLink)`
  &.active {
    font-weight: bold;
  }
`;

const StyledNavbar = styled(Navbar)`
  background-color: #093738;
`;

const StyledNavLogo = styled.img`
  margin-top: -5px;
  margin-right: 10px;
`;
