import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios, { AxiosResponse } from 'axios';
import Config from '../../config';
import { Account, defaultAccount } from '../../types/account';
import { Patient } from '../../types/patient';
import { Order } from '../../types/order';
import { Role } from '../../types/role';
import { hasRole } from '../../utils/auth';
import AuditTrailTab from '../AuditTrailTab';
import AccountTab from './AccountTab';
import PatientsTab from './PatientsTab';
import GDPRTab from './GDPRTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

type AccountResponse = {
  account: Account;
  patients: Patient[];
  orders: Order[];
  pharmacyNames: {
    [id: string]: string;
  };
};

export default () => {
  const { accountId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [account, setAccount] = useState<Account | null>(null);
  const [patients, setPatients] = useState<Patient[]>([]);

  const fetchAccount = async () => {
    try {
      setLoading(true);
      const url = Config.getConfigVar('accountsEndpoint');
      const result = await axios.get<any, AxiosResponse<AccountResponse>>(`${url}/${accountId}`);
      setAccount(result.data.account);
      setPatients(result.data.patients);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (accountId === 'new') {
      setAccount(defaultAccount);
      setLoading(false);
    } else {
      fetchAccount();
    }
    // eslint-disable-next-line
  }, [accountId]);

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error || !account) {
    return <Alert variant="danger">Error loading user account</Alert>;
  }

  return (
    <>
      <Row className="my-3">
        <Col sm={6}>
          <h2>
            {account.email}{' '}
            {account.nhsLoginSub && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-im1">Patient used NHS login</Tooltip>}
              >
                <FontAwesomeIcon icon="heartbeat" />
              </OverlayTrigger>
            )}
          </h2>
        </Col>
      </Row>
      <Tabs defaultActiveKey="details" id="pharmacy-tabs" className="my-3">
        <Tab eventKey="details" title="User Account" className="my-3 mx-2">
          <AccountTab account={account} fetchAccount={fetchAccount} />
        </Tab>
        <Tab eventKey="patients" title="Linked Patients" className="my-3 mx-2">
          <PatientsTab patients={patients} />
        </Tab>
        <Tab eventKey="audittrail" title="Audit Trail" className="my-3 mx-2">
          <AuditTrailTab query={`entityId=${account.id}`} />
        </Tab>
        {hasRole(Role.Admin_Users_Delete) && (
          <Tab eventKey="gdpr" title="GDPR" className="my-3 mx-2">
            <GDPRTab account={account} />
          </Tab>
        )}
      </Tabs>
    </>
  );
};
