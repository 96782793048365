import React, { useState, useEffect } from 'react';
import Config from '../config';
import { Audit } from '../types/audit';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import format from 'date-fns/format';

type AuditTrailsTab = {
  query: string;
  includeUser?: boolean;
};

const AuditTrailTab = ({ query, includeUser = true }: AuditTrailsTab) => {
  const url = Config.getConfigVar('auditTrailEndpoint');

  const [auditTrail, setAuditTrail] = useState<Audit[]>([]);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const fetchAudits = async () => {
    try {
      setLoading(true);
      const result = await axios.get(`${url}?${query}`);
      setAuditTrail(result.data);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAudits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h4 className="mb-4">Audit Trail</h4>
      <Row>
        <Col sm={8}>
          <Table size="sm">
            <thead>
              <tr>
                <td>Action</td>
                {includeUser && <td>User</td>}
                <td>Time</td>
              </tr>
            </thead>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <tbody>
                {auditTrail.map((audit, i) => (
                  <tr key={i}>
                    <td>{audit.description}</td>
                    {includeUser && <td>{audit.email}</td>}
                    <td>{format(new Date(audit.createdDt), Config.getConfigVar('dateFormat'))}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default AuditTrailTab;
