import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Config from '../config';
import { Account } from '../types/account';

type ModalProps = {
  account: Account;
  showModal: boolean;
	setShowModal: (show: boolean) => void;
	useWeb?: boolean;
};

export default ({ account, showModal, setShowModal, useWeb = false }: ModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setLoading(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger">There was an error sending the reset password email</Alert>
        )}
        <p>
          This will send reset password instructions to <strong>{account.email}</strong>.
        </p>
        <p>The link in their email will be valid for 24 hours.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        {loading ? (
          <Button variant="primary" disabled>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <span className="sr-only">Sending...</span>
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={async () => {
              try {
                setLoading(true);
								let url = Config.getConfigVar('resetPasswordEndpoint');
                await axios.post(url, { 
                  email: account.email,
                  platform: useWeb ? 'web' : null ,
                  redirectURL: useWeb ? window.origin : null
                });
                setShowModal(false);
              } catch (e) {
                setError(true);
              }
              setLoading(false);
            }}
          >
            Send instructions
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};