import React, { ReactNode, useState } from 'react';
import axios from 'axios';
import Config from '../config';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

type GDPRModalProps = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  children: ReactNode;
  deleteRequestBody: object;
};

export default ({ showModal, setShowModal, children, deleteRequestBody }: GDPRModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setLoading(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>GDPR Delete Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">There was an error deleting the account</Alert>}
        <Alert variant="warning">
          {children}
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        {loading ? (
          <Button variant="primary" disabled>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <span className="sr-only">Deleting...</span>
          </Button>
        ) : (
          <Button
            variant="danger"
            onClick={async () => {
              try {
                setLoading(true);
                const url = Config.getConfigVar('gdprDeleteEndpoint');
                await axios.post(url, deleteRequestBody);
                setShowModal(false);
              } catch (e) {
                setError(true);
              }
              setLoading(false);
            }}
          >
            Delete
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
