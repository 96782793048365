import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Offer from './Offer';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Spinner, Table } from 'react-bootstrap';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types/role';
import { Offer as OfferType } from '../../types/offer';
import Config from '../../config';
import format from 'date-fns/format'
import ConfirmDeleteModal from '../ConfirmDeleteModal';

export default () => {
  const [offers, setOffers] = useState<OfferType[]>([]);
  const [selectedOfferToDelete, setSelectedOfferToDelete] = useState<OfferType | null>(null);
  const match = useRouteMatch();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const shortDateFormat = Config.getConfigVar('shortDateFormat');
  const url = Config.getConfigVar('pharmacyOffersEndpoint'); 

  const fetchOffers = async () => {
    try {
      const offerResult = await axios.get(`${url}`);
      setLoading(true);
      setError(null);
      setOffers(offerResult.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Failed to load offers');
    }
  };

  const deleteOffer = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!selectedOfferToDelete) throw Error('Offer to delete not specified');

      await axios.delete(url, {
        data: {
          offerId: selectedOfferToDelete?.id,
        },
      });
      setOffers((offers) => offers.filter((offer) => offer.id !== selectedOfferToDelete?.id));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Failed to delete offer');
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
  <>
    <Switch>
      <Route path={`${match.path}/:offerId`}>
        <Offer fetchOffers={() => fetchOffers()}/>
      </Route>
      <Route path={match.path}>
        {error && <Alert variant="danger">{error}</Alert>}
        <Row className="my-3">
          <Col sm={3}>
          <h2>Offers</h2>
          </Col>
        </Row>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <Row className="mt-4 mb-3 mx-1">
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Offer Code</th>
                    <th>Live Date</th>
                    <th>Expiry Date</th>
                    {hasRole(Role.Admin_Offers_Edit) && (
                      <>
                        <th>Edit</th>
                        <th>Delete</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {offers.map((offer) => (
                    <tr key={offer.id}>
                      <td>{offer.title}</td>
                      <td>{offer.offerCode}</td>
                      <td>{format(new Date(offer.liveOnDate), shortDateFormat)}</td>
                      <td>{format(new Date(offer.expiry), shortDateFormat)}</td>
                      {hasRole(Role.Admin_Offers_Edit) && (
                        <>
                          <td>
                            <Link to={`${match.url}/${offer.id}`}>
                              <FontAwesomeIcon icon="edit" />
                            </Link>
                          </td>
                          <td>
                            <Button variant="link" onClick={() => setSelectedOfferToDelete(offer)}>
                              <FontAwesomeIcon icon="trash-alt" />
                            </Button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </>
        )}
        {hasRole(Role.Admin_Offers_Edit) && (
          <Row className="my-3">
            <Col sm={3}>
              <p className="text-left">
              <Link to={`${match.url}/new`}>
                <Button variant="primary">
                  <FontAwesomeIcon icon="plus-circle" /> Add Offer
                </Button>
              </Link>
              </p>
            </Col>
          </Row>
        )}
      </Route>
    </Switch>
    <ConfirmDeleteModal
      show={selectedOfferToDelete != null}
      onHide={() => setSelectedOfferToDelete(null)}
      onConfirm={deleteOffer}
      name={selectedOfferToDelete?.title || ''}
      type="offer"
    />
  </>
  );
}