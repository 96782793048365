import { actionTypes } from '../actions/user';
import { Role } from '../types/role';

export type userStateType = {
  username: string | null;
  refreshToken: string | null;
  pharmacyIDs: string[];
  roles: Role[];
};

export const userState: userStateType = {
  username: null,
  refreshToken: null,
  pharmacyIDs: [],
  roles: []
};

export default (state = userState, action: Action): userStateType => {
  switch (action.type) {
    case actionTypes.SIGN_IN:
      return {
        ...state,
        username: action.payload.username,
      };
    case actionTypes.SET_REFRESH_TOKEN: {
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
      };
    }
    case actionTypes.ADD_PERMISSIONS: {
      return {
        ...state,
        pharmacyIDs: action.payload.pharmacyIDs,
        roles: action.payload.roles
      }
    }
    case actionTypes.SIGN_OUT:
      return userState;
    default:
      return state;
  }
};
