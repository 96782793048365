import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import axios, { AxiosResponse } from 'axios';
import Config from '../../config';
import styled from 'styled-components';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type StatsResponse = {
  newOrdersCount: number;
  newERDOrdersCount: number;
  withGPOrdersCount: number;
  stuckOrdersWithPharmacyCount: number;
  stuckOrdersWithGPCount: number;
  usersCount: number;
  newUsersCount: number;
  im1UsersCount: number;
  accountsCount: number;
  newAccountsCount: number;
  withPharmacyCount: number;
};

const StatsJumbo = styled(Jumbotron)`
  background-color: #dbe9e9;
`;

const StatHeader = styled.h3`
  margin-bottom: 0.75em;
  color: gray;
`;

const Stat = styled.span`
  font-weight: 700;
  color: black;
`;

const LinkStyle = {
  textDecoration: 'none',
  color: 'black',
};

export default () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [stats, setStats] = useState<StatsResponse>();
  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const popover = (
    <Popover id="popover-positioned-scrolling-right" className="popover-main">
      <Popover.Title as="h3">
        {stats?.stuckOrdersWithPharmacyCount} Orders Stuck with Pharmacies
      </Popover.Title>
      <Popover.Content>
        <Button
          className="btn-block"
          disabled={downloadingCSV}
          onClick={async () => {
            await downloadOrders();
          }}
        >
          {downloadingCSV ? 'Loading…' : 'Download CSV Report'}
        </Button>
      </Popover.Content>
    </Popover>
  );

  const downloadOrders = async () => {
    try {
      setDownloadingCSV(true);
      const url = Config.getConfigVar('ordersCsvEndpoint');
      const result = await axios.get<any, AxiosResponse<any>>(`${url}`, {
        responseType: 'blob',
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setError(true);
    } finally {
      setDownloadingCSV(false);
    }
  };

  const fetchStats = async () => {
    try {
      setLoading(true);
      const statsUrl = Config.getConfigVar('backOfficeStatsEndpoint');
      const statsResult = await axios.post(statsUrl);
      setStats(statsResult.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line
  }, []);

  let content = null;

  if (loading) {
    content = <Spinner animation="border" variant="primary" />;
  } else if (error) {
    content = <Alert variant="danger">Error loading stats</Alert>;
  } else {
    content = stats && (
      <Col sm="8">
        <StatsJumbo>
          <StatHeader>
            <Stat>{stats.newOrdersCount}</Stat> new orders
          </StatHeader>
          <StatHeader>
            <Stat>{stats.newERDOrdersCount}</Stat> new ERD orders
          </StatHeader>
          <StatHeader>
            <Stat>{stats.withGPOrdersCount}</Stat> orders with GPs
          </StatHeader>
          <StatHeader>
            <Stat>{stats.stuckOrdersWithGPCount}</Stat> orders stuck with GP
          </StatHeader>
          <StatHeader>
            <Stat>{stats.withPharmacyCount}</Stat> orders with pharmacies
          </StatHeader>
          <StatHeader>
            <Stat>
              {stats.stuckOrdersWithPharmacyCount > 0 ? (
                <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose={true}>
                  <Link style={LinkStyle} to="/" onClick={(e) => e.preventDefault()}>
                    {stats.stuckOrdersWithPharmacyCount}
                  </Link>
                </OverlayTrigger>
              ) : (
                <>{stats.stuckOrdersWithPharmacyCount}</>
              )}
            </Stat>{' '}
            orders stuck with pharmacies
          </StatHeader>
          <StatHeader>
            <Stat>{stats.newUsersCount}</Stat> new patients this week
          </StatHeader>
          <StatHeader>
            <Stat>{stats.usersCount}</Stat> total patients
          </StatHeader>
          <StatHeader>
            <Stat>{stats.im1UsersCount}</Stat> patients connected to GP (IM1)
          </StatHeader>
          <StatHeader>
            <Stat>{stats.newAccountsCount}</Stat> new user accounts this week
          </StatHeader>
          <StatHeader>
            <Stat>{stats.accountsCount}</Stat> total user accounts
          </StatHeader>
        </StatsJumbo>
      </Col>
    );
  }

  return (
    <>
      <Row className="my-3">
        <Col sm={3}>
          <h2>Dashboard</h2>
        </Col>
      </Row>
      <Row>{content}</Row>
    </>
  );
};
