export type Patient = {
	id: string;
	accountId: string;
	pharmacyId: string;
	nhsNumber: string;
	title: string;
	firstName: string;
	lastName: string;
	dateOfBirth: string;
	primaryContactNumber: string;
	secondaryContactNumber: string | null,
	address1: string;
	address2: string | null;
	address3: string | null;
	county: string | null;
	city: string;
	postcode: string;
	consentSignedDt: string;
	consentSigned: boolean;
	gpnhsNumber: string;
	gpName: string;
	gpAddress1: string;
	gpAddress2: string | null;
	gpAddress3: string | null;
	gpAddress4: string | null;
	gpAddress5: string | null;
	gpPostcode: string;
	gpContactNumber: string | null;
	doctorName: string | null;
	doctorNHSNumber: string | null;
  patientIntegration: PatientIntegration | null;
  reminderHistory?: ReminderHistoryItem[];
}

export enum PatientIntegrationType {
	EMIS,
	TPP
}

type PatientIntegration = {
	thirdPartyPatientId: string;
	integrationType: PatientIntegrationType;
}

export type ReminderHistoryItem = {
  openedDt: string;
  reminderDt?: string;
  reminderTime?: string;
  reminderType: 'Reorder' | 'TakeMedicine';
  reminderId: string;
  reminderTitle: string;
  reminderBody: string;
  orderId: string;
  orderItemId: string;
  patientId: string;
};
