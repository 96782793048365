import React, { useState, useEffect } from 'react';
import { PharmacyTabType } from './Pharmacy';
import { Offer } from '../../types/offer';
import axios from 'axios';
import Config from '../../config';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import { hasPharmacyEdit } from '../../utils/auth';
import { Alert, Spinner } from 'react-bootstrap';

export default ({ pharmacy, setPharmacy }: PharmacyTabType) => {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const offersByIds = pharmacy.offersByIds || [];

  const availableOffers = offers.map((offer) => ({
    value: offer.id,
    label: offer.title,
  }));

  const offersProvided = availableOffers.filter((offer) => offersByIds.includes(offer.value));

  const fetchOffers = async () => {
    try {
      setLoading(true);
      setError(null);
      const offersRes = await axios.get(`${Config.getConfigVar('pharmacyOffersEndpoint')}`)
      setOffers(offersRes.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Failed to load offers');
    }
  };

  useEffect(() => {
    fetchOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <Alert variant="danger">Error loading offers</Alert>;
  }

  return (
    <>
      <h4 className="mb-4">Offers Offered</h4>
      <Row className="mb-4">
        <Col sm={8}>
          {loading 
          ? <Spinner animation="border" />
          : <Select
              isMulti
              isDisabled={!hasPharmacyEdit()}
              options={availableOffers}
              value={offersProvided}
              onChange={(selected) => {
                if (!selected) {
                  selected = [];
                }
                setPharmacy({
                  ...pharmacy,
                  offersByIds: Array.isArray(selected)
                    ? selected.map((offer) => offer.value)
                    // @ts-ignore
                    : [selected.value],
                });
              }}
            />
          }
        </Col>
      </Row>
    </>
  )

}