export type Account = {
	id: string;
	accountStatus: string;
	email: string;
	createdDt: string;
	platform: string;
	accountNum: string;
	nhsLoginSub: string | null;
}

export const defaultAccount: Account = {
	id: '',
	accountStatus: '',
	email: '',
	createdDt: '',
	platform: '',
	accountNum: '',
	nhsLoginSub: null,
};