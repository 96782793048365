import React  from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PatientTabProps } from './Patient';
import Table from 'react-bootstrap/Table';
import format from 'date-fns/format';

const timestampFormat = 'dd/MM/yyyy HH:mm:ss.SSS';

export default ({ patient }: PatientTabProps) => {
  if (!patient) {
    return null;
  }

  return (
    <>
      <h4 className="mb-4">Reminder history</h4>
      <Row>
        <Col sm={8}>
          <Table size="sm">
            <thead>
              <tr>
                <th>Time received</th>
                <th>Reminder title</th>
                <th>Reminder message</th>
              </tr>
            </thead>
              <tbody>
                {patient?.reminderHistory
                  ?.map((reminder, i) => (
                    <tr key={i}>
                      <td>
                        {format(new Date(reminder.openedDt), timestampFormat)}
                      </td>
                      <td>{reminder.reminderTitle}</td>
                      <td>{reminder.reminderBody}</td>
                    </tr>
                  ))}
              </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
