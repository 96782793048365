import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, Link, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import axios, { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../../config';
import Pharmacy from './Pharmacy';
import { Pharmacy as PharmacyType } from '../../types/pharmacy';
import Form from 'react-bootstrap/Form';
import { hasRole } from '../../utils/auth';
import { Role } from '../../types/role';
import { PharmacyPortalAccessLogApiResponse } from '../../types/pharmacyPortalAccessLog';
import format from 'date-fns/format';
import compareAsc from 'date-fns/compareAsc';
import differenceInDays from 'date-fns/differenceInDays';

export default () => {
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pharmacies, setPharmacies] = useState<PharmacyType[]>([]);
  const [accessLogs, setAccessLogs] = useState<PharmacyPortalAccessLogApiResponse>();
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<string>('businessName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filterParam, setFilterParam] = useState('includeInactive=false&includeActive=true');
  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const fetchPharmacies = async () => {
    try {
      setLoading(true);
      const pharmUrl = Config.getConfigVar('pharmaciesEndpoint');
      const logsUrl = Config.getConfigVar('pharmacyAccessLogsEndpoint');
      const pharmResult = await axios.get(`${pharmUrl}?${filterParam}`);
      const logsResult = await axios.get(logsUrl);
      setPharmacies(pharmResult.data);
      setAccessLogs(logsResult.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const downloadPharmacies = async () => {
    try {
      setDownloadingCSV(true);
      const url = Config.getConfigVar('pharmaciesCsvEndpoint');
      const result = await axios.get<any, AxiosResponse<any>>(`${url}?${filterParam}`, {
        responseType: 'blob',
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setError(true);
    } finally {
      setDownloadingCSV(false);
    }
  };

  useEffect(() => {
    fetchPharmacies();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchPharmacies();
    // eslint-disable-next-line
  }, [filterParam, location.pathname]);

  const dateFormat = Config.getConfigVar('dateFormat');

  const pharmacySearch = (pharmacy: PharmacyType) => {
    const searchString = search.toLocaleLowerCase().trim();

    return (
      pharmacy.businessName.toLocaleLowerCase().includes(searchString) ||
      pharmacy.address1.toLocaleLowerCase().includes(searchString) ||
      pharmacy.address2.toLocaleLowerCase().includes(searchString) ||
      pharmacy.address3.toLocaleLowerCase().includes(searchString) ||
      pharmacy.city.toLocaleLowerCase().includes(searchString) ||
      pharmacy.postcode.toLocaleLowerCase().includes(searchString) ||
      pharmacy.code.toLocaleLowerCase().includes(searchString) ||
      pharmacy.branchNumber.toLocaleLowerCase().includes(searchString)
    );
  };

  let content = null;

  const getSortIcon = (sortKey: string) => {
    if (sortKey !== sortBy) {
      return (
        <FontAwesomeIcon
          icon="sort"
          onClick={() => {
            setSortBy('lastAccessDate');
            setSortDirection('asc');
          }}
        />
      );
    }
    if (sortDirection === 'asc') {
      return <FontAwesomeIcon icon="sort-up" onClick={() => setSortDirection('desc')} />;
    }
    return <FontAwesomeIcon icon="sort-down" onClick={() => setSortDirection('asc')} />;
  };

  if (loading) {
    content = <Spinner animation="border" variant="primary" />;
  } else if (error) {
    content = <Alert variant="danger">Error loading pharmacies</Alert>;
  } else {
    const filteredPharmacies = pharmacies
      .sort((a, b) => {
        let asc;
        if (sortBy === 'lastAccessDate') {
          const lastAccessA = accessLogs ? accessLogs[a.id] : null;
          const lastAccessB = accessLogs ? accessLogs[b.id] : null;
          if (!lastAccessA && !lastAccessB) {
            asc = 0;
          } else if (!lastAccessA) {
            asc = -1;
          } else if (!lastAccessB) {
            asc = 1;
          } else {
            const dateA = new Date(lastAccessA.lastAccessDate);
            const dateB = new Date(lastAccessB.lastAccessDate);
            asc = compareAsc(dateA, dateB);
          }
        } else {
          asc = a[sortBy] === b[sortBy] ? 0 : a[sortBy] < b[sortBy] ? -1 : 1;
        }
        return sortDirection === 'asc' ? asc : asc * -1;
      })
      .filter(pharmacySearch);

    content =
      filteredPharmacies.length === 0 ? (
        <em>No pharmacies found</em>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Number</th>
              <th>Code</th>
              <th>Name {getSortIcon('businessName')}</th>
              <th>Address</th>
              <th>City</th>
              <th>Postcode</th>
              <th>Portal Accessed {getSortIcon('lastAccessDate')}</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {filteredPharmacies.map((pharmacy) => {
              const lastAccess = accessLogs ? accessLogs[pharmacy.id] : null;

              return (
                <tr key={pharmacy.id}>
                  <td>{pharmacy.branchNumber}</td>
                  <td>{pharmacy.code}</td>
                  <td>
                    <Link to={`${match.url}/${pharmacy.id}`}>{pharmacy.businessName}</Link>
                  </td>
                  <td>{pharmacy.address1}</td>
                  <td>{pharmacy.city}</td>
                  <td>{pharmacy.postcode}</td>
                  <td
                    className={
                      lastAccess &&
                      differenceInDays(Date.now(), new Date(lastAccess.lastAccessDate)) > 1
                        ? 'text-danger'
                        : ''
                    }
                  >
                    {lastAccess ? format(new Date(lastAccess.lastAccessDate), dateFormat) : ''}
                  </td>
                  <td>
                    <Link to={`${match.url}/${pharmacy.id}`}>
                      <FontAwesomeIcon icon="edit" />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
  }

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:pharmacyId`}>
          <Pharmacy />
        </Route>
        <Route path={match.path}>
          <Row className="my-3">
            <Col sm={3}>
              <h2>Pharmacies</h2>
            </Col>
            <Col sm={6}>
              <Form>
                <Form.Control
                  type="text"
                  placeholder="Search by pharmacy name, address, code, branch number"
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}
                />
              </Form>
            </Col>
            {hasRole(Role.Admin_Pharmacies_Add) && (
              <Col sm={3}>
                <p className="text-right">
                  <Link to={`${match.url}/new`}>
                    <Button variant="primary">
                      <FontAwesomeIcon icon="plus-circle" /> Add new pharmacy
                    </Button>
                  </Link>
                </p>
              </Col>
            )}
          </Row>
          <Row className="mx-1 mb-4">
            <Col sm={2}>
              <Form>
                <Form.Control
                  as="select"
                  value={filterParam}
                  onChange={(e: any) => setFilterParam(e.target.value)}
                >
                  <option value={'includeInactive=true&includeActive=true'}>All</option>
                  <option value={'includeInactive=false&includeActive=true'}>Active only</option>
                  <option value={'includeInactive=true&includeActive=false'}>Inactive only</option>
                </Form.Control>
              </Form>
            </Col>
            <Col sm={10}>
              {pharmacies.length > 0 && (
                <p className="text-right">
                  <strong>Count: </strong>
                  {pharmacies.length}{' '}
                  <Button href="#" onClick={async () => await downloadPharmacies()} disabled={downloadingCSV} size="sm">
                    {downloadingCSV ? 'Exporting...' : 'Export'}
                  </Button>
                </p>
              )}
            </Col>
          </Row>
          <Row className="mx-1">{content}</Row>
        </Route>
      </Switch>
    </>
  );
};
