import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Account } from '../../types/account';
import Config from '../../config';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import EditAccountStatusModal from '../EditAccountStatusModal';
import Form from 'react-bootstrap/Form';
import { Role } from '../../types/role';
import { hasRole } from '../../utils/auth';
import ResetPasswordModal from '../ResetPasswordModal';

export default ({
  account,
  fetchAccount,
  setAccount,
  username,
}: {
  account: Account;
  fetchAccount: Function;
  setAccount: (account: Account) => void;
  username: string;
}) => {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showEditStatusModal, setShowEditStatusModal] = useState(false);

  const dateFormat = Config.getConfigVar('dateFormat');

  return (
    <>
      {account.id === '' ? (
        <>
          <p>Set email, add roles and pharmacies and then click add to complete the process.</p>
          <p>
            New admin users will receive an email asking them to create their password before they
            can log in.
          </p>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Email
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                type="text"
                required
                placeholder="Email"
                value={account.email}
                onChange={(e: any) => {
                  setAccount({
                    ...account,
                    email: e.target.value,
                  });
                }}
              />
            </Col>
          </Form.Group>
        </>
      ) : (
        <>
          <Row className="mt-4 mb-3">
            <Col sm={2}>Email</Col>
            <Col sm={10}>{account.email}</Col>
          </Row>
          <Row className="my-3">
            <Col sm={2}>Signed Up</Col>
            <Col sm={10}>{format(new Date(account.createdDt), dateFormat)}</Col>
          </Row>
          <Row className="my-3">
            <Col sm={2}>Status</Col>
            <Col sm={10}>
              {account.accountStatus}
              {hasRole(Role.Admin_Full) && (
                <Button
                  variant="link"
                  style={{ marginTop: '-5px' }}
                  disabled={account.email === username}
                  onClick={() => setShowEditStatusModal(true)}
                >
                  <FontAwesomeIcon icon="edit" />
                </Button>
              )}
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Button variant="warning" onClick={() => setShowResetPasswordModal(true)}>
                <FontAwesomeIcon icon="key" /> Reset Password
              </Button>
            </Col>
          </Row>
          <ResetPasswordModal
            showModal={showResetPasswordModal}
            setShowModal={setShowResetPasswordModal}
            account={account}
            useWeb
          />
          <EditAccountStatusModal
            showModal={showEditStatusModal}
            setShowModal={setShowEditStatusModal}
            account={account}
            fetchAccount={fetchAccount}
          />
        </>
      )}
    </>
  );
};