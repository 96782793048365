import React, { useState, useEffect } from 'react';
import { Account } from '../../types/account';
import Config from '../../config';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'react-bootstrap/Table';
import { Role } from '../../types/role';
import Modal from 'react-bootstrap/Modal';
import { Group } from '../../types/group';
import Select, { ValueType } from 'react-select';
import { hasRole } from '../../utils/auth';

const getGroupSelectLabel = (group: Group) => group.name;

type OptionType = {
  value: string;
  label: string;
};

export default ({
  account,
  associatedGroups,
  setAssociatedGroups,
}: {
  account: Account;
  associatedGroups: {
    [id: string]: string;
  };
  setAssociatedGroups: (groups: { [id: string]: string }) => void;
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [newGroup, setNewGroup] = useState<ValueType<OptionType>>(null);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const url = Config.getConfigVar('getGroupsEndpoint');
        const result = await axios.get(url);
        setGroups(result.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(true);
      }
    };

    if (showAddModal) {
      fetchGroups();
    }
  }, [showAddModal]);

  return (
    <>
      <p>
        These are the groups {account.email} has access to in the <strong>Pharmacy Portal</strong>.
        They will be able to see and edit all pharmacies within the group.
      </p>
      <Table hover>
        <thead>
          <tr>
            <th>Group</th>
            {hasRole(Role.Admin_Full) && <th>Remove access</th>}
          </tr>
        </thead>
        <tbody>
          {Object.values(associatedGroups).length ? (
            Object.entries(associatedGroups).map(([id, name]) => (
              <tr key={id}>
                <td>{name}</td>
                {hasRole(Role.Admin_Full) && (
                  <td>
                    <Button
                      variant="link"
                      onClick={() => {
                        const groups = { ...associatedGroups };
                        delete groups[id];
                        setAssociatedGroups(groups);
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>
                <em>User does not currently have access to any groups</em>
              </td>
            </tr>
          )}
          {/* 
            Limit on pharmacy IDs that can be stored in JWT, see 
            https://iwavicenna.atlassian.net/jira/software/projects/AP2/boards/3?selectedIssue=AP2-1230 
          */}
          {hasRole(Role.Admin_Full) &&
            (Object.values(associatedGroups).length < 6 ? (
              <tr>
                <td colSpan={2}>
                  <Button variant="primary" onClick={() => setShowAddModal(true)}>
                    <FontAwesomeIcon icon="plus-circle" /> Add group access
                  </Button>
                </td>
              </tr>
            ) : (
              <Alert variant="warning">
                A maximum of 6 groups can be added, please remove one to add new access
              </Alert>
            ))}
        </tbody>
      </Table>
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Group Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <Alert variant="danger">Error loading groups</Alert>
          ) : loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <p>Select a group from the list below.</p>
              <p>
                This will give the user access to the pharmacy portal and the ability to edit the
                group and its pharmacies.
              </p>
              <Select
                options={groups.map((g) => ({ value: g.id, label: getGroupSelectLabel(g) }))}
                value={newGroup}
                onChange={setNewGroup}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!newGroup}
            onClick={() => {
              const groupObj = groups.find((g) => g.id === (newGroup as OptionType).value);
              if (groupObj) {
                setAssociatedGroups({
                  ...associatedGroups,
                  [groupObj.id]: groupObj.name,
                });
              }
              setShowAddModal(false);
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
