import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../../config';
import { Group, defaultGroup } from '../../types/group';
import Modal from 'react-bootstrap/Modal';
import { hasRole, hasPharmacyEdit } from '../../utils/auth';
import { Role } from '../../types/role';
import AuditTrailTab from '../AuditTrailTab';
import DetailTab from './DetailTab';
import PharmaciesTab from './PharmaciesTab';

export default () => {
  const { groupId } = useParams<{ groupId: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [group, setGroup] = useState<Group | null>(null);
  const [initialGroup, setInitialGroup] = useState<Group | null>(null);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [detailsError, setDetailsError] = useState(false);
  const [associatedPharmacies, setAssociatedPharmacies] = useState({});
  const [initialPharmacies, setInitialPharmacies] = useState({});

  const fetchGroup = async () => {
    try {
      const url = Config.getConfigVar('getGroupsEndpoint');
      const result = await axios.get(`${url}/${groupId}`);

      setGroup(result.data.group);
      setInitialGroup(result.data.group);
      setAssociatedPharmacies(result.data.pharmacyNames);
      setInitialPharmacies(result.data.pharmacyNames);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Failed to load pharmacy');
    }
  };

  useEffect(() => {
    if (groupId === 'new') {
      setInitialGroup(defaultGroup);
      setGroup(defaultGroup);
      setLoading(false);
    } else {
      fetchGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const updateGroup = async () => {
    setModalLoading(true);
    try {
      const url = Config.getConfigVar('updateGroupEndpoint');
      const result = await axios.put(url, { group, pharmacyIds: Object.keys(associatedPharmacies) });
      setUpdateError(null);
      if (result.data?.id !== groupId) {
        history.push(`/groups/${result.data?.id}`);
      } else {
        setLoading(true);
        fetchGroup();
      }
    } catch (e) {
      setError('Failed to save group');
    } finally {
      setModalLoading(false);
      setShowSaveModal(false);
    }
  };

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (!group) {
    return <Alert variant="danger">Error loading group</Alert>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const handleSubmit = (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      if (form.querySelectorAll('#group-tabs-tabpane-details .form-control:invalid').length) {
        setDetailsError(true);
      }
      setShowSaveModal(false);
    } else {
      setDetailsError(false);
      setShowSaveModal(true);
    }
    setValidated(true);
  };

  const detailsTitle = (
    <>
      Details{' '}
      {detailsError && (
        <Badge pill variant="danger">
          !
        </Badge>
      )}
    </>
  );

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {updateError && <Alert variant="danger">{updateError}</Alert>}
      {/* Block enter key submission with a disabled submit */}
      <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true"></button>
      <Row className="my-3">
        <Col sm={6}>
          <h2>Group: {groupId === 'new' ? 'New Group' : initialGroup?.name}</h2>
        </Col>
        {hasPharmacyEdit() && (
          <Col sm={6}>
            <p className="text-right">
              <Button
                variant="secondary"
                disabled={initialGroup === group && initialPharmacies === associatedPharmacies}
                onClick={() => {
                  setGroup(initialGroup);
                  setValidated(false);
                  setDetailsError(false);
                }}
              >
                <FontAwesomeIcon icon="history" /> Reset
              </Button>{' '}
              <Button
                variant="primary"
                disabled={initialGroup === group && initialPharmacies === associatedPharmacies}
                type="submit"
              >
                <FontAwesomeIcon icon="save" /> Save
              </Button>
            </p>
          </Col>
        )}
      </Row>
      <Tabs defaultActiveKey="details" id="group-tabs" className="my-3">
        <Tab eventKey="details" title={detailsTitle} className="my-3 mx-2">
          <DetailTab group={group} setGroup={setGroup} groupId={groupId} />
        </Tab>
        {hasRole(Role.Admin_Full) && (
          <Tab eventKey="pharmacies" title="Pharmacy Access" className="my-3 mx-2">
            <PharmaciesTab
              associatedPharmacies={associatedPharmacies}
              setAssociatedPharmacies={setAssociatedPharmacies}
            />
          </Tab>
        )}
        {hasRole(Role.Admin_Full) && (
          <Tab eventKey="audittrail" title="Audit Trail" className="my-3 mx-2">
            <AuditTrailTab query={`entityId=${group.id}`} />
          </Tab>
        )}
      </Tabs>
      <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to save changes?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSaveModal(false)}>
            Cancel
          </Button>
          {modalLoading ? (
            <Button variant="primary" disabled>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="sr-only">Saving...</span>
            </Button>
          ) : (
            <Button variant="primary" onClick={updateGroup}>
              Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export type GroupTabType = {
  group: Group;
  setGroup: (group: Group) => void;
};
