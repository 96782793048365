import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../config';
import { Patient as PatientType } from '../types/patient';
import format from 'date-fns/format';

export default ({ patients }: { patients: PatientType[] }) => {
  const shortDateFormat = Config.getConfigVar('shortDateFormat');
  const dateFormat = Config.getConfigVar('dateFormat');
  return (
    <Table>
      <thead>
        <tr>
          <th>First name</th>
          <th>Last name</th>
          <th>DOB</th>
          <th>Primary contact</th>
          <th>Address 1</th>
          <th>Postcode</th>
          <th>Registered</th>
          <th>IM1</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
        {patients.map((patient) => (
          <tr key={patient.id}>
            <td>
              <Link to={`/patients/${patient.id}`}>{patient.firstName}</Link>
            </td>
            <td>
              <Link to={`/patients/${patient.id}`}>{patient.lastName}</Link>
            </td>
            <td>{format(new Date(patient.dateOfBirth), shortDateFormat)}</td>
            <td>{patient.primaryContactNumber}</td>
            <td>{patient.address1}</td>
            <td>{patient.postcode}</td>
            <td>{format(new Date(patient.consentSignedDt), dateFormat)}</td>
            <td>
              {patient.patientIntegration ? (
                <FontAwesomeIcon icon="check-circle" className="text-primary" />
              ) : (
                ''
              )}
            </td>
            <td>
              <Link to={`/patients/${patient.id}`}>
                <FontAwesomeIcon icon="edit" />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
