import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faClinicMedical,
  faCloudDownloadAlt,
  faEdit,
  faExclamationTriangle,
  faHeartbeat,
  faHistory,
  faKey,
  faNotesMedical,
  faPlusCircle,
  faSave,
  faSignOutAlt,
  faSort,
  faSortUp,
  faSortDown,
  faTimesCircle,
  faTrashAlt,
  faUserMd,
  faClipboard
} from '@fortawesome/free-solid-svg-icons';
import AppPage from './components/AppPage.container';
import Groups from './components/group/Groups';
import Pharmacies from './components/pharmacy/Pharmacies';
import Accounts from './components/userAccounts/Accounts';
import AdminAccounts from './components/adminAccounts/AdminAccounts';
import Patients from './components/patients/Patients';
import Services from './components/Services';
import Offers from './components//offers/Offers';
import HelpFiles from './components/HelpFiles';
import Reports from './components/bookings/Reports';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import CreatePassword from './components/CreatePassword.container';
import Dashboard from './components/dashboard';

library.add(
  faCheckCircle,
  faClinicMedical,
  faCloudDownloadAlt,
  faEdit,
  faExclamationTriangle,
  faHeartbeat,
  faHistory,
  faKey,
  faNotesMedical,
  faPlusCircle,
  faSave,
  faSignOutAlt,
  faSort,
  faSortUp,
  faSortDown,
  faTimesCircle,
  faTrashAlt,
  faUserMd,
  faClipboard
);

export default () => {
  return (
    <HashRouter basename="">
      <Switch>
        <Route path="/createpassword/:token">
          <CreatePassword />
        </Route>
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/dashboard">
          <AppPage>
            <Dashboard />
          </AppPage>
        </Route>
        <Route path="/groups">
          <AppPage>
            <Groups />
          </AppPage>
        </Route>
        <Route path="/pharmacies">
          <AppPage>
            <Pharmacies />
          </AppPage>
        </Route>
        <Route path="/admins">
          <AppPage>
            <AdminAccounts />
          </AppPage>
        </Route>
        <Route path="/accounts">
          <AppPage>
            <Accounts />
          </AppPage>
        </Route>
        <Route path="/patients">
          <AppPage>
            <Patients />
          </AppPage>
        </Route>
        <Route path="/services">
          <AppPage>
            <Services />
          </AppPage>
        </Route>
        <Route path="/offers">
          <AppPage>
            <Offers />
          </AppPage>
        </Route>
        <Route path="/help">
          <AppPage>
            <HelpFiles />
          </AppPage>
        </Route>
        <Route path="/booking-reports">
          <AppPage>
            <Reports />
          </AppPage>
        </Route>
      </Switch>
    </HashRouter>
  );
};
