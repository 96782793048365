import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Account } from '../../types/account';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GDPRDeleteModal from '../GDPRDeleteModal';

export default ({ account }: { account: Account }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Row className="my-3">
      <Col>
        <Button variant="danger" onClick={() => setShowModal(true)}>
          <FontAwesomeIcon icon="trash-alt" /> GDPR Delete
        </Button>
      </Col>
      <GDPRDeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        deleteRequestBody={{ accountId: account.id }}
      >
        <p>
          This will delete the account and all patients associated with{' '}
          <strong>{account.email}</strong>.
        </p>
        <p>
          <strong>This action is irreversible.</strong>
        </p>
      </GDPRDeleteModal>
    </Row>
  );
};