import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PatientTabProps } from './Patient';

export default ({ patient, pharmacy }: PatientTabProps) => {

  return (
    <>
      <Row className="my-4 border-bottom">
        <Col sm={2}>Pharmacy</Col>
        <Col sm={7}>
          <p>
            <Link to={`/pharmacies/${pharmacy?.id}`}>
              {pharmacy?.businessName} ({pharmacy?.code})
            </Link>
          </p>
          <p>Branch {pharmacy?.branchNumber}</p>
          {pharmacy?.address1 && <p>{pharmacy?.address1}</p>}
          {pharmacy?.address2 && <p>{pharmacy?.address2}</p>}
          {pharmacy?.address3 && <p>{pharmacy?.address3}</p>}
          {pharmacy?.city && <p>{pharmacy?.city}</p>}
          {pharmacy?.postcode && <p>{pharmacy?.postcode}</p>}
        </Col>
      </Row>
      <Row className="my-4">
        <Col sm={2}>GP Surgery</Col>
        <Col sm={7}>
          <p>{patient?.gpName}</p>
          <p>NHS Number: {patient?.gpnhsNumber}</p>
          {patient?.gpAddress1 && <p>{patient?.gpAddress1}</p>}
          {patient?.gpAddress2 && <p>{patient?.gpAddress2}</p>}
          {patient?.gpAddress3 && <p>{patient?.gpAddress3}</p>}
          {patient?.gpAddress4 && <p>{patient?.gpAddress4}</p>}
          {patient?.gpPostcode && <p>{patient?.gpPostcode}</p>}
          {patient?.gpContactNumber && <p>{patient?.gpContactNumber}</p>}
        </Col>
      </Row>
      <Row className="my-4">
        <Col sm={2}>GP Doctor</Col>
        <Col sm={7}>
          <p>{patient?.doctorName}</p>
          <p>NHS Number: {patient?.doctorNHSNumber}</p>
        </Col>
      </Row>
    </>
  );
};