import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../config';
import { Service } from '../types/service';
import axios from 'axios';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { Spinner } from 'react-bootstrap';
import { Role } from '../types/role';
import { hasRole } from '../utils/auth';
import { format } from 'date-fns';

export default () => {
  const [services, setServices] = useState<Service[]>([]);
  const [serviceRequests, setServiceRequests] = useState<any[]>([]);
  const [newService, setNewService] = useState('');
  const [, setError] = useState(false); //TODO: Show some ui for errors/loading
  const [loading, setLoading] = useState(false);
  const [selectedServiceToDelete, setSelectedServiceToDelete] = useState<Service | null>(null);
  const [mapping, setMapping] = useState(false);
  const [mappingMessage, setMappingMessage] = useState('');
  const url = Config.getConfigVar('pharmacyServicesEndpoint');

  const fetchServices = async () => {
    try {
      setLoading(true);
      setError(false);
      const result: any = await axios.get(url);
      setServices(result.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const fetchServiceRequests = async () => {
    try {
      setLoading(true);
      setError(false);
      const result: any = await axios.get(Config.getConfigVar('serviceRequestsEndpoint'));
      setServiceRequests(result.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const createService = async (serviceName: string) => {
    try {
      setLoading(true);
      setError(false);
      const result: any = await axios.put(url, {
        name: serviceName,
      });
      addService(result.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const addService = (service: Service) => setServices((services) => services.concat(service));

  const deleteService = async () => {
    try {
      setLoading(true);
      setError(false);

      if (!selectedServiceToDelete) throw Error('Service to delete not specified');

      await axios.delete(url, {
        data: {
          serviceId: selectedServiceToDelete.id,
        },
      });
      setServices((services) => services.filter((s) => s.id !== selectedServiceToDelete.id));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const reviewRequest = async (serviceRequestId: any, approved: any) => {
    try {
      await axios.post(Config.getConfigVar('reviewServiceRequestEndpoint'), {
        serviceRequestId,
        approved,
      });
      await fetchServices();
      await fetchServiceRequests();
    } catch {}
  };

  const mapServicesFromAppointedd = async () => {
    try {
      setMappingMessage('');
      setMapping(true);
      await axios.post(Config.getConfigVar('mapServicesFromAppointeddEndpoint'));
      setMappingMessage('Services mapped successfully!');
      setMapping(false);
    } catch {
      setMappingMessage('There was an issue mapping services - please contact help and support');
      setMapping(false);
    }
  }

  useEffect(() => {
    fetchServices();
    fetchServiceRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row className="my-3">
        <Col>
          <h2>Services</h2>
        </Col>
      </Row>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Row className="mt-4 mb-3 mx-1">
            <Table size="sm">
              <thead>
                <th>Service</th>
                {hasRole(Role.Admin_Services_Edit) && (
                  <>
                    <th>Edit</th>
                    <th>Delete</th>
                  </>
                )}
              </thead>
              <tbody>
                {services.map((service) => (
                  <ServiceRow
                    service={service}
                    onDelete={() => setSelectedServiceToDelete(service)}
                    onSave={async (newServiceName) => {
                      try {
                        setLoading(true);
                        const result = await axios.put(url, {
                          serviceId: service.id,
                          name: newServiceName,
                        });
                        setServices((services) =>
                          services.map((s) => (s.id === result.data.id ? result.data : s))
                        );
                        setLoading(false);
                      } catch (e) {
                        setError(true);
                        setLoading(false);
                      }
                    }}
                  />
                ))}
                {hasRole(Role.Admin_Services_Edit) && (
                  <tr>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="New service"
                        value={newService}
                        onChange={(e: any) => setNewService(e.target.value)}
                      />
                    </td>
                    <td colSpan={2}>
                      <Button
                        variant="link"
                        disabled={newService.trim() === ''}
                        onClick={() => {
                          createService(newService);
                          setNewService('');
                        }}
                      >
                        <FontAwesomeIcon icon="plus-circle" />
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
          {serviceRequests.length > 0 && (
            <>
              <h4 className="mt-4 mb-4">Services Pending Approval</h4>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>Pharmacy</th>
                    <th>Requested By</th>                    
                    <th>Requested On</th>
                    <th className="text-center">Approve</th>
                    <th className="text-center">Reject</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceRequests.map((req, i) => (
                    <tr key={i}>
                      <td>{req.requestedServiceName}</td>
                      <td>{req.pharmacyName}</td>
                      <td>{req.requestedByEmail}</td>
                      <td>{format(new Date(req.requestDt), Config.getConfigVar('dateFormat'))}</td>
                      <td className="text-center">
                        <Button
                          variant="link"
                          onClick={async () => {
                            setLoading(true);
                            await reviewRequest(req.id, true);
                            fetchServices();
                            fetchServiceRequests();
                          }}
                        >
                          <FontAwesomeIcon icon="check-circle" />
                        </Button>
                      </td>
                      <td className="text-center">
                        <Button
                          variant="link"
                          onClick={async () => {
                            setLoading(true);
                            await reviewRequest(req.id, false);
                            fetchServices();
                            fetchServiceRequests();
                          }}
                        >
                          <FontAwesomeIcon icon="times-circle" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </>
      )}
      <ConfirmDeleteModal
        show={selectedServiceToDelete != null}
        onHide={() => setSelectedServiceToDelete(null)}
        onConfirm={deleteService}
        name={selectedServiceToDelete?.name || ''}
        type="service"
      />
    </>
  );
};

type ServiceRowProps = {
  onDelete: () => void;
  onSave: (newServiceName: string) => Promise<void>;
  service: Service;
};
const ServiceRow = ({ onDelete, service, onSave }: ServiceRowProps) => {
  const [editing, setEditing] = useState(false);
  const [serviceName, setServiceName] = useState(service.name);

  if (editing)
    return (
      <tr>
        <td>
          <FormControl value={serviceName} onChange={(e: any) => setServiceName(e.target.value)} />
        </td>
        {hasRole(Role.Admin_Services_Edit) && (
          <>
            <td>
              <Button
                variant="primary"
                onClick={async () => {
                  await onSave(serviceName);
                  setEditing(false);
                }}
              >
                Save
              </Button>
            </td>
            <td>
              <Button
                variant="secondary"
                onClick={() => {
                  setEditing(false);
                  setServiceName(service.name);
                }}
              >
                Cancel
              </Button>
            </td>
          </>
        )}
      </tr>
    );
  return (
    <tr>
      <td>{service.name}</td>
      <td>
        <Button variant="link" onClick={() => setEditing(true)}>
          <FontAwesomeIcon icon="edit" />
        </Button>
      </td>
      <td>
        <Button variant="link" onClick={onDelete}>
          <FontAwesomeIcon icon="trash-alt" />
        </Button>
      </td>
    </tr>
  );
};
