import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

type SaveModalProps = {
  saving: boolean;
  showSaveModal: boolean;
  setShowSaveModal: (show: boolean) => void;
  onSave: () => void;
};

export default ({ showSaveModal, setShowSaveModal, onSave, saving }: SaveModalProps) => (
  <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Save Changes</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to save changes?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowSaveModal(false)}>
        Cancel
      </Button>
      {saving ? (
        <Button variant="primary" disabled>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          <span className="sr-only">Saving...</span>
        </Button>
      ) : (
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);
