import React, {  } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Config from '../../config';
import Form from 'react-bootstrap/Form';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { PatientTabProps } from "./Patient";

export default ({ patient, account, setPatient }: PatientTabProps) => {
  if (!patient || !setPatient || !account) {
    return null;
  }

  const dateFormat = Config.getConfigVar('dateFormat');

  return (
    <>
      <Row className="my-4">
        <Col sm={2}>Account</Col>
        <Col sm={7}>
          <Link to={`/accounts/${account.id}`}>{account.email}</Link>
        </Col>
      </Row>
      <Row className="my-4">
        <Col sm={2}>Consent Signed</Col>
        <Col sm={7}>{format(new Date(patient.consentSignedDt), dateFormat)}</Col>
      </Row>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          First Name
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required
            placeholder="First Name"
            value={patient.firstName}
            onChange={(e: any) => {
              setPatient({
                ...patient,
                firstName: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Last Name
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required
            placeholder="Last Name"
            value={patient.lastName}
            onChange={(e: any) => {
              setPatient({
                ...patient,
                lastName: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Date of Birth
        </Form.Label>
        <Col sm={7}>
          <DatePicker
            required
            dateFormat="dd/MM/yyyy"
            selected={new Date(patient.dateOfBirth)}
            onChange={(date) => {
              if (date) {
                setPatient({
                  ...patient,
                  dateOfBirth: date.toISOString()
                });
              }
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          NHS Number
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            disabled
            value={patient.nhsNumber}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Primary Contact Number
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="number"
            required
            placeholder="Number"
            value={patient.primaryContactNumber}
            onChange={(e: any) => {
              setPatient({
                ...patient,
                primaryContactNumber: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Secondary Contact Number
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="number"
            placeholder="Number (optional)"
            value={patient.secondaryContactNumber ?? ''}
            onChange={(e: any) => {
              setPatient({
                ...patient,
                secondaryContactNumber: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Address 1
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required
            placeholder="Address 1"
            value={patient.address1}
            onChange={(e: any) => {
              setPatient({
                ...patient,
                address1: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Address 2
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            placeholder="Address 1 (optional)"
            value={patient.address2 ?? ''}
            onChange={(e: any) => {
              setPatient({
                ...patient,
                address2: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Address 3
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            placeholder="Address 3 (optional)"
            value={patient.address3 ?? ''}
            onChange={(e: any) => {
              setPatient({
                ...patient,
                address3: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          City
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required
            placeholder="City"
            value={patient.city ?? ''}
            onChange={(e: any) => {
              setPatient({
                ...patient,
                city: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          County
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            placeholder="County (optional)"
            value={patient.county ?? ''}
            onChange={(e: any) => {
              setPatient({
                ...patient,
                county: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Postcode
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required
            placeholder="Postcode"
            value={patient.postcode ?? ''}
            onChange={(e: any) => {
              setPatient({
                ...patient,
                postcode: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
    </>
  );
};