import { connect } from 'react-redux';
import AppPage from './AppPage';
import { GlobalState } from '../reducers';
import { getUsername } from '../selectors/user';
import { signOut } from '../actions/user';

const mapStateToProps = (state: GlobalState) => ({
  username: getUsername(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  signOut: () => dispatch(signOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(AppPage);
