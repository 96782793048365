import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Config from '../../config';
import { Account } from '../../types/account';

type ModalProps = {
  account: Account;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export default ({ account, showModal, setShowModal }: ModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setLoading(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Force Logout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">An error occurred</Alert>}
        <p>
          This will force <strong>{account.email}</strong> to sign in next time they open the app.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        {loading ? (
          <Button variant="primary" disabled>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <span className="sr-only">Working...</span>
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={async () => {
              try {
                setLoading(true);
                const url = Config.getConfigVar('forceLogoutEndpoint');
                await axios.post(url, {
                  accountId: account.id,
                });
                setShowModal(false);
              } catch (e) {
                setError(true);
              }
              setLoading(false);
            }}
          >
            Force Logout
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
