import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Config from '../../config';
import { Account } from '../../types/account';
import format from 'date-fns/format';
import { getPlatformText } from './Accounts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditAccountStatusModal from '../EditAccountStatusModal';
import { Role } from '../../types/role';
import { hasRole } from '../../utils/auth';
import ResetPasswordModal from '../ResetPasswordModal';
import ForceLogoutModal from './ForceLogoutModal';

export default ({ account, fetchAccount }: { account: Account; fetchAccount: Function }) => {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showEditStatusModal, setShowEditStatusModal] = useState(false);
  const [showForceLogoutModal, setShowForceLogoutModal] = useState(false);

  const dateFormat = Config.getConfigVar('dateFormat');

  return (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={2}>Email</Col>
        <Col sm={10}>{account.email}</Col>
      </Row>
      <Row className="my-3">
        <Col sm={2}>Signed Up</Col>
        <Col sm={10}>{format(new Date(account.createdDt), dateFormat)}</Col>
      </Row>
      <Row className="my-3">
        <Col sm={2}>Platform</Col>
        <Col sm={10}>{getPlatformText(account.platform)}</Col>
      </Row>
      <Row className="my-3">
        <Col sm={2}>Status</Col>
        <Col sm={10}>
          {account.accountStatus}
          {hasRole(Role.Admin_Users_Edit) && (
            <Button
              variant="link"
              style={{ marginTop: '-5px' }}
              onClick={() => setShowEditStatusModal(true)}
            >
              <FontAwesomeIcon icon="edit" />
            </Button>
          )}
        </Col>
      </Row>
      {hasRole(Role.Admin_Users_Edit) && (
        <Row className="my-3">
          <Col>
            <Button variant="warning" onClick={() => setShowResetPasswordModal(true)}>
              <FontAwesomeIcon icon="key" /> Reset Password
            </Button>
            <Button
              variant="warning"
              onClick={() => setShowForceLogoutModal(true)}
              className="ml-4"
            >
              <FontAwesomeIcon icon="sign-out-alt" /> Force Logout
            </Button>
          </Col>
        </Row>
      )}
      <ResetPasswordModal
        showModal={showResetPasswordModal}
        setShowModal={setShowResetPasswordModal}
        account={account}
      />
      <ForceLogoutModal
        showModal={showForceLogoutModal}
        setShowModal={setShowForceLogoutModal}
        account={account}
      />
      <EditAccountStatusModal
        showModal={showEditStatusModal}
        setShowModal={setShowEditStatusModal}
        account={account}
        fetchAccount={fetchAccount}
      />
    </>
  );
};
