export type Group = {
	[key: string]: any;
	id: string;
	name: string;
	keyContact: string;
	telephoneNumber: string;
	email: string;
	live: boolean;
	numberOfPharmaciesAllowed: number;
	useCustomLogo: boolean;
	bookingApiKey: string;
	bookingAdminApiKey: string;
	createdDt: string;
}

export const defaultGroup: Group = {
  id: '',
	name: '',
	keyContact: '',
	telephoneNumber: '',
	email: '',
	live: false,
	numberOfPharmaciesAllowed: 10,
	useCustomLogo: false,
	bookingApiKey: '',
	bookingAdminApiKey: '',
	createdDt: ''
};