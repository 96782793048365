import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GDPRDeleteModal from '../GDPRDeleteModal';
import { PatientTabProps } from "./Patient";

export default ({ patient }: PatientTabProps) => {
  const [showModal, setShowModal] = useState(false);

  if (!patient) {
    return null;
  }

  return (
    <Row className="my-3">
      <Col>
        <Button variant="danger" onClick={() => setShowModal(true)}>
          <FontAwesomeIcon icon="trash-alt" /> GDPR Delete
        </Button>
      </Col>
      <GDPRDeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        deleteRequestBody={{ patientId: patient.id }}
      >
        <p>
          This will delete the patient{' '}
          <strong>
            {patient.firstName} {patient.lastName}
          </strong>
          .
        </p>
        <p>
          If this is the only patient associated with a user account, the account will also be
          deleted.
        </p>
        <p>
          <strong>This action is irreversible.</strong>
        </p>
      </GDPRDeleteModal>
    </Row>
  );
};