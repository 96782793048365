import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

type ConfirmDeleteModalProps = {
  show: boolean;
  onHide: () => void;
  onConfirm: () => Promise<void>;
  name: string;
  type: string;
};
export default ({ show, onHide, onConfirm, name, type }: ConfirmDeleteModalProps) => {
  const [loading, setLoading] = useState(false);

  if (!show) {
    return null;
  }

  return (
    <Modal show={show} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete Pharmacy {type.charAt(0).toUpperCase() + type.slice(1)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete <strong>{name}</strong>? This will also remove it
        from all pharmacies that have this {type}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} variant="secondary" onClick={() => onHide()}>
          No
        </Button>
        <Button
          disabled={loading}
          variant="danger"
          onClick={async () => {
            setLoading(true);
            await onConfirm();
            setLoading(false);
            onHide();
          }}
        >
          {loading ? 'Deleting' : 'Yes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
