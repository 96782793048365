import React, { useEffect, useState, useRef } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import axios, { AxiosResponse } from 'axios';
import Config from '../../config';
import Patient from './Patient';
import { Patient as PatientType } from '../../types/patient';
import Form from 'react-bootstrap/Form';
import useDebounce from '../../hooks/useDebounce';
import PatientsTable from '../PatientsTable';
import Pagination from '../Pagination';
import Button from 'react-bootstrap/Button';

const itemsPerPage = 50;

type PatientResponse = {
  patients: PatientType[];
  items: number;
  currentPage: number;
  totalPages: number;
};

export default () => {
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const [error, setError] = useState(false);
  const [patients, setPatients] = useState<PatientType[]>([]);
  const [totalPatients, setTotalPatients] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [filterParam, setFilterParam] = useState('includeIM1=true&includeNonIM1=true');
  const [search, setSearch, signal] = useDebounce('', 500, {
    leading: false,
    trailing: true,
  });
  const prevSearchRef = useRef<string>();
  const prevFilterRef = useRef<string>();

  const fetchPatients = async (searchTerm: string) => {
    try {
      setLoading(true);
      setTotalPatients(null);
      const url = Config.getConfigVar('patientsEndpoint');
      const result = await axios.get<any, AxiosResponse<PatientResponse>>(
        `${url}?q=${searchTerm}&offset=${
          (page - 1) * itemsPerPage
        }&limit=${itemsPerPage}&${filterParam}`
      );
      setPatients(result.data.patients);
      setPages(result.data.totalPages);
      setTotalPatients(result.data.items);
      setLoading(false);
    } catch (e) {
      setTotalPatients(null);
      setLoading(false);
      setError(true);
    }
  };

  const downloadPatients = async () => {
    try {
      setDownloadingCSV(true);
      const url = Config.getConfigVar('patientsCsvEndpoint');
      const result = await axios.get<any, AxiosResponse<any>>(`${url}?q=${search}&${filterParam}`, {
        responseType: 'blob',
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setTotalPatients(null);
      setError(true);
    } finally {
      setDownloadingCSV(false);
    }
  };

  useEffect(() => {
    fetchPatients('');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (search !== prevSearchRef.current || filterParam !== prevFilterRef.current) {
      setPage(1);
      prevSearchRef.current = search;
      prevFilterRef.current = filterParam;
    }
    fetchPatients(search);
    // As this is debounced, we don't want to watch for changes to search string itself
    // eslint-disable-next-line
  }, [signal, page, filterParam]);

  let content = null;

  if (loading) {
    content = <Spinner animation="border" variant="primary" />;
  } else if (error) {
    content = <Alert variant="danger">Error loading patients</Alert>;
  } else if (patients.length === 0) {
    content = <em>No patients found</em>;
  } else {
    content = (
      <>
        <PatientsTable patients={patients} />
        {pages > 1 && <Pagination currentPage={page} totalPages={pages} setPage={setPage} />}
      </>
    );
  }

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:patientId`}>
          <Patient />
        </Route>
        <Route path={match.path}>
          <Row className="my-3">
            <Col sm={3}>
              <h2>Patients</h2>
            </Col>
            <Col sm={6}>
              <Form>
                <Form.Control
                  type="text"
                  placeholder="Search by first name, last name, contact number"
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}
                />
              </Form>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={2}>
              <Form>
                <Form.Control
                  as="select"
                  value={filterParam}
                  onChange={(e: any) => setFilterParam(e.target.value)}
                >
                  <option value={'includeIM1=true&includeNonIM1=true'}>All</option>
                  <option value={'includeIM1=true&includeNonIM1=false'}>IM1 only</option>
                  <option value={'includeIM1=false&includeNonIM1=true'}>Non-IM1 only</option>
                </Form.Control>
              </Form>
            </Col>
            <Col sm={10}>
              {totalPatients !== null && (
                <p className="text-right">
                  <strong>Count: </strong>
                  {totalPatients}{' '}
                  <Button href="#" onClick={async () => await downloadPatients()} disabled={downloadingCSV} size="sm">
                    {downloadingCSV ? 'Exporting...' : 'Export'}
                  </Button>
                </p>
              )}
            </Col>
          </Row>
          <Row className="mx-1">{content}</Row>
        </Route>
      </Switch>
    </>
  );
};
